import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';




@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.css']
})
export class AboutMeComponent implements OnInit {

  	user: any;
	company: any;
	token: string;
	options: any;
	cities: any;

	froalaOpts: any;


	form: FormGroup;
	uploadForm: FormGroup;
	formData: any;
	submitted = false;

	image: any;

	otherRace = false;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder,
		// private fe: FroalaEditorModule
	) { }

	ngOnInit() {

		this.getUser();

		this.form = this.fb.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            date_of_birth: ['', [Validators.required]],
            gender: ['', [Validators.required]],
            race_id: ['', [Validators.required]],
            nationality_id: ['', [Validators.required]],
            about: ['', [Validators.required, Validators.maxLength(3600)]],
            address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            country_id: ['', [Validators.required]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: [{value: '', disabled: true}, [Validators.required, Validators.email]],
            current_salary: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            expected_salary: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
			facebook_link: ['', []],
			twitter_link: ['', []],
			google_link: ['', []],
			linkedin_link: ['', []],
			nric: ['', []],
			passport: ['', []],
			race: ['', []],

        });

        this.uploadForm = this.fb.group({
	      profile: ['']
	    });

	    this.froalaOpts = {
		    charCounterMax: 3500,
		};

		this.setNationalityValidators();
	}

	get f() { return this.form.controls; }

	setNationalityValidators() {
		const nric = this.form.get('nric');
		const passport = this.form.get('passport');

		this.form.get('nationality_id').valueChanges
		.subscribe(nationality_id => {

			this.form.get('nric').clearValidators();
			this.form.get('passport').clearValidators();

 			this.form.get('nric').updateValueAndValidity();
 			this.form.get('passport').updateValueAndValidity();

			if (nationality_id === 109) {
				nric.setValidators([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(12)]);
				passport.setValidators([Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(10)]);
			} else {
				nric.setValidators([Validators.pattern('^[0-9]*$'), Validators.minLength(12)]);
				passport.setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9]+$'), Validators.minLength(10)]);
			}

			nric.updateValueAndValidity();
			passport.updateValueAndValidity();
		});
	}

	getUser(): void {

		if (localStorage.getItem('user') !== null) {
			this.token = JSON.parse(localStorage.getItem('user')).token;

			if (!this.token) {
				this.router.navigate(['/']);
			}

			this.index();
		} else {
			this.router.navigate(['/']);
		}

	}

	index(): void {

		this.apiService.personalAboutMeOptions().subscribe(data => {

			this.options 		= data.data;

			this.aboutMeImage();
			this.aboutMe();
		});
	}

	onChange(value): void {
	    if (value == '') {
			this.cities = [];
			this.form.controls['city'].setValue('');
		} else {
			this.cities = [];
			this.form.controls['city'].setValue('');
	    	this.getCitiesOfState(value);
		}
	}

	race(value): void {
		if (value == 7) { // 7 == Others
			this.form.get('race_id').clearValidators();
			this.form.controls['race_id'].setValue('');
			this.form.get('race').setValidators([Validators.required]);
			this.otherRace = true;
		}
	}

	closeOthers(field): void {
		if (field == 'race') {
			this.form.get('race').clearValidators();
			this.form.controls['race'].setValue('');
			this.form.get('race_id').setValidators([Validators.required]);
			this.otherRace = false;
		}
	}

	getCitiesOfState(stateId): void {

		this.cities = (this.options.states.filter(state => state.name == stateId))[0].cities;
	}

	aboutMe(): void {

		this.apiService.personalAboutMe().subscribe(data => {

			this.user 				= data.data;
			if(this.user.state)
				this.getCitiesOfState(this.user.state);

			this.form.setValue({

				first_name: this.user.first_name,
				last_name: this.user.last_name,
				date_of_birth : this.user.date_of_birth,
				gender : this.user.gender,
				race_id : this.user.race_id,
				nationality_id : this.user.nationality_id,
				about : this.user.about,
				address_1 : this.user.address_1,
				address_2 : this.user.address_2,
				city : this.user.city,
				state : this.user.state,
				zipcode : this.user.zipcode,
				country_id : this.user.country_id,
				contact_number : this.user.contact_number,
				contact_email : this.user.contact_email,
				current_salary : this.user.current_salary,
				expected_salary : this.user.expected_salary,
				facebook_link : this.user.facebook_link,
				twitter_link : this.user.twitter_link,
				google_link : this.user.google_link,
				linkedin_link : this.user.linkedin_link,
				nric : this.user.nric,
				passport : this.user.passport,
				race: ''
			});
		});
	}

	updateAboutMe(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalUpdateAboutMe(this.form.value).subscribe(data => {

				this.user 		= data.data;

				this.submitted = false;
				window.scroll(0, 0);
				alert(data.message);

				this.closeOthers('race');
				this.index();
			});
		} else {
			window.scroll(0, 0);
		}
	}

	uploadFile(event): void {
	    const elem = event.target;
	    if (elem.files.length > 0) {
	      this.image = elem.files[0];

	      const formData: FormData = new FormData();
    	  formData.append('file', elem.files[0], elem.files[0].name);

	      this.apiService.personalImage(formData).subscribe(data => {

				if (!data['status']) {
					alert(data['message']);
				}
			});
	    }
	}

	aboutMeImage(): void {

		this.apiService.personalGetImage().subscribe(data => {

				this.image = data;
			});
	}
}
