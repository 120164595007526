import { Component, OnInit, NgModule } 	from '@angular/core';
import { NgForm } 						from '@angular/forms';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
	selector: 'app-company-profile',
	templateUrl: './company-profile.component.html',
	styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {

	data: any;
	company: any;
	token: string;
	// subsidiaries: any;

	image: any;

	print = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location

	) { }

	ngOnInit( ) {
		this.index();
	}

	index(): void {
		window.scroll(0, 0);

		this.apiService.corporateProfile().subscribe(data => {
			if (!data.status) {
				alert(data.message);
			} else {
				this.data 	= data.data;
				this.aboutCompanyImage();
			}
		});
	}

	viewProfile(): void {

		const HTML_Width = (document.getElementById('bodyMain')).clientWidth;
		const HTML_Height = (document.getElementById('bodyMain')).clientHeight;

		const top_left_margin = 0;

		const PDF_Width 	= HTML_Width + (top_left_margin * 2);
		const PDF_Height 	= (PDF_Width * 1.5) + (top_left_margin * 2);

		const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

		html2canvas(document.getElementById('bodyMain'), {allowTaint: true}).then(canvas => {

			const imgData = canvas.toDataURL('image/png', 1.0);

			const pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
			pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, HTML_Width, HTML_Height);

			for (let i = 1; i <= totalPDFPages; i++) {
				pdf.addPage(PDF_Width, PDF_Height);
				pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), HTML_Width, HTML_Height);
			}

			pdf.save('profile.pdf');
		});
	}

	aboutCompanyImage(): void {

		this.apiService.corporateGetImage().subscribe(data => {

			this.image = data;
		});
	}
}
