import { Component, OnInit } from '@angular/core';
import { ApiService } 						from  '../api.service';
import { ActivatedRoute } 					from '@angular/router';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
	selector: 'app-maa-admin-corporate-profile',
	templateUrl: './maa-admin-corporate-profile.component.html',
	styleUrls: ['./maa-admin-corporate-profile.component.css']
})
export class MaaAdminCorporateProfileComponent implements OnInit {

	data: any;

	constructor(
		private apiService:  ApiService,
		private route: ActivatedRoute,
		) { }

	ngOnInit() {
		this.index();
	}


	index(): void {

		const id = +this.route.snapshot.paramMap.get('id');

		this.apiService.getAdminCorporateProfile(id).subscribe(data => {

			if (data.status == false) {
				alert(data.message);
			} else {
				this.data  					= data.data;
			}
		});
	}

	viewProfile(): void {

        const HTML_Width = (document.getElementById('bodyMain')).clientWidth;
        const HTML_Height = (document.getElementById('bodyMain')).clientHeight;

        const top_left_margin = 0;

        const PDF_Width = HTML_Width + (top_left_margin * 2);
        const PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);

        const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

        html2canvas(document.getElementById('bodyMain'), {allowTaint: true}).then(canvas => {

            const imgData = canvas.toDataURL('image/png', 1.0);

            const pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
            // pdf.viewerPreferences({'FitWindow': false}, true)
            pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, HTML_Width, HTML_Height);

            for (let i = 1; i <= totalPDFPages; i++) {
                pdf.addPage(PDF_Width, PDF_Height);
                pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), HTML_Width, HTML_Height);
            }

            pdf.save('profile.pdf');

        });
    }

}
