import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
import { saveAs } from 'file-saver';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-saved-candidates',
  templateUrl: './saved-candidates.component.html',
  styleUrls: ['./saved-candidates.component.css']
})
export class SavedCandidatesComponent implements OnInit {

  	post: any;

	userId: null;

	tags: any;

	saveForm: FormGroup;


	constructor(
		// private route: ActivatedRoute,
		private apiService: ApiService,
		// private router: Router,
		// private location: Location,
		private fb: FormBuilder

		) {

	}

	ngOnInit() {

		this.getTags();

        this.saveForm = this.fb.group({
			tag_id: ['', Validators.required],
			candidate_user_id: ['', Validators.required],
        });
	}

	get s() { return this.saveForm.controls; }

	getCandidates(tagId) {

		this.apiService.corporateGetCandidatesByTag(tagId).subscribe(data => {

			if (data['status']) {
				// this.submitted 	= false;
				this.post 		= data['data'];
			} else {
				alert(data['message']);
			}
		});
	}

    tag(value): void {

		this.post = null;

		if (value !== '') {
			this.saveForm.controls['tag_id'].setValue(value);
			this.getCandidates(value);
		}
	}

	getTags(): void {

		this.apiService.corporateGetTags().subscribe(data => {

			this.tags = data['data'];
		});
	}

	removeCandidate(id, i): void {
		// this.saved = true;
		this.saveForm.controls['candidate_user_id'].setValue(id);

		if (this.saveForm.valid) {

			this.apiService.corporateRemoveCandidate(this.saveForm.value).subscribe(data => {

				if (data.status) {
					this.getCandidates(this.saveForm.get('tag_id').value);
				} else {
					alert(data.message);
				}
			});
		} else {
			window.scroll(0, 0);
		}
	}

	getResume(id: any): void {

		this.apiService.corporateGetCandidateResume(id).subscribe(data => {

				const blob = new Blob([data], {type: 'application/pdf'});
				if (blob.size > 2) {
					const file = window.URL.createObjectURL(blob);
					saveAs(file, 'resume.pdf');
				} else {
					alert('No Resume document uploaded previously');
				}
			});
	}

	profile(id: number): void {

        window.open( '/corporate/search-candidates/' + id);
    }

}
