import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import {Globals} 			from '../globals';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	data: any;
	company: any;
	token: string;

	image: any;

	print = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private globals: Globals
	) { }

	ngOnInit() {
		this.index();
	}

	index(): void {
		window.scroll(0, 0);

		this.apiService.personalProfile().subscribe(data => {
			if (data.status) {
				this.data 	= data.data;
				this.aboutMeImage();

				this.globals.newMessageCount = this.data.inbox_count;
			}
		});
	}

	aboutMeImage(): void {

		this.apiService.personalGetImage().subscribe(data => {

			this.image = data;
		});
	}

	viewProfile() {

		const HTML_Width = (document.getElementById('bodyMain')).clientWidth;
		const HTML_Height = (document.getElementById('bodyMain')).clientHeight - 100;

		const top_left_margin = 0;

		const PDF_Width = HTML_Width + (top_left_margin * 2);
		const PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);

		const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

		html2canvas(document.getElementById('bodyMain'), {allowTaint: true}).then(canvas => {

			const imgData = canvas.toDataURL('image/png', 1.0);

			const pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
			// pdf.viewerPreferences({'FitWindow': false}, true)
			pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin, HTML_Width, HTML_Height);

			for (let i = 1; i <= totalPDFPages; i++) {
				pdf.addPage(PDF_Width, PDF_Height);
				pdf.addImage(imgData, 'PNG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), HTML_Width, HTML_Height);
			}

			pdf.save('profile.pdf');

		});
	}
}
