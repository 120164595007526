import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-jobs-i-want-edit',
  templateUrl: './jobs-i-want-edit.component.html',
  styleUrls: ['./jobs-i-want-edit.component.css']
})
export class JobsIWantEditComponent implements OnInit {

  	options: any;
	object: any;

	form: FormGroup;
    submitted = false;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
		) { }

	ngOnInit() {
		this.index();

		this.form = this.fb.group({
            keyword: ['', [Validators.required]],
            expected_salary: ['', [Validators.required]],
            location: ['', [Validators.required]],
            specialization_id: ['', [Validators.required]],
            position_level_id: ['', [Validators.required]],
            education_field_id: ['', [Validators.required]],
            job_type: ['', [Validators.required]],
            // years_range_id: ['', [Validators.required]],
            experience_years: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            email_frequency: ['', []]

        });
	}

	get f() { return this.form.controls; }

	index(): void {

		this.apiService.personalSeekingOptions().subscribe(data => {

			this.options = data.data;
			this.get();
		});
	}

	get(): void {
		const id = +this.route.snapshot.paramMap.get('id');
		this.apiService.personalSeeking(id).subscribe(data => {

			this.object = data.data;
			console.log(this.object);

			this.form.setValue({

				keyword: this.object.keyword,
				expected_salary: this.object.expected_salary,
				location : this.object.location,
				specialization_id : this.object.specialization_id,
				position_level_id : this.object.position_level_id,
				education_field_id : this.object.education_field_id,
				job_type : this.object.job_type,
				// years_range_id :this.object.years_range_id,
				experience_years : this.object.experience_years,
				email_frequency : this.object.email_frequency

			});
		});
	}

	update(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalUpdateSeeking(this.form.value, this.object.id).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.submitted = false;
				}

				alert(data.message);
				this.router.navigate(['/personal/jobs-i-want']);
			});
		}
	}

	cancel(): void {

		this.router.navigate(['/personal/jobs-i-want']);
	}

}
