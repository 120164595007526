import { Component, OnInit } 			from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

@Component({
  selector: 'app-my-bookmarks',
  templateUrl: './my-bookmarks.component.html',
  styleUrls: ['./my-bookmarks.component.css']
})
export class MyBookmarksComponent implements OnInit {

	json;
	bookmarks;
	bookmarksLength;

  	token: string;

  constructor(
  		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
  ) { }



  ngOnInit() {

  	this.index();
  }

 //  getUser(): void {

	// 	if(localStorage.getItem('user') !== null) {
	// 		this.token = JSON.parse(localStorage.getItem('user')).token;

	// 		if(!this.token)
	// 			this.router.navigate(['/']);

	// 		this.getIndex();
	// 	}
	// 	else {
	// 		this.router.navigate(['/']);
	// 	}
	// }





  public index(): void {
		this.apiService.personalBookmarks().subscribe(data => {

			window.scroll(0, 0);

			if (data.status == false) {
				alert(data.message);
			} else {
				this.json 						= data;
				this.bookmarks  				= data.data;
				this.bookmarksLength  			= Object.keys(this.bookmarks).length;
			}
		});
	}

	public delete(id: number): void {

		this.apiService.personalBookmarkDelete(id).subscribe(data => {

			if (data.status == true) {
				alert(data.message);
				// HIDE APPLY BUTTON
				this.refresh();
			} else {
				alert(data.message);
				this.router.navigate(['/bookmarks']);
			}
		});
	}


	public refresh(): void {
		window.scroll(0, 0);
		this.index();
	}

}
