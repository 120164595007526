import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-language',
	templateUrl: './language.component.html',
	styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

	user: any;
	lang: any;
	langs: any;
	options: any;
	new = true;

	form: FormGroup;
    submitted = false;

    otherLanguage = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

	ngOnInit() {

		this.index();

		this.form = this.fb.group({
            language_id: ['', 	Validators.required],
            proficiency: ['', 	Validators.required],
            language: ['', 	[]],
        });
	}

	get f() { return this.form.controls; }

	index(): void {

		this.apiService.personalLanguageOptions().subscribe(data => {

			this.options = data.data;
			this.getAll();
		});
	}

	getAll(): void {

		this.apiService.personalLanguages().subscribe(data => {

			this.langs = data.data;
		});
	}

	create(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalCreateLanguage(this.form.value).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.submitted = false;
				} else {
					alert(data.message);
				}

				this.setNew();
				this.index();
			});
		}

	}

	edit(lang: any): void {

		window.scroll(0, 0);
		this.new = false;
		this.lang = lang;

		this.form.setValue({
		  language_id: lang.language_id,
		  proficiency: lang.proficiency,
		  language: ''
		});
	}

	update(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalUpdateLanguage(this.form.value, this.lang.id).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.submitted = false;
				}

				this.setNew();
				this.index();
			});
		}
	}

	delete(lang: any): void {

		this.apiService.personalDeleteLanguage(lang.id).subscribe(data => {

			this.setNew();
			this.getAll();
		});
	}

	setNew(): void {

		this.new = true;
		this.form.reset();
		this.submitted = false;

		this.closeOthers('language');
	}

	other(value, field): void {
		if (field == 'language' && value == 9) { // 7 == Others
			this.form.get('language_id').clearValidators();
			this.form.controls['language_id'].setValue('');
			this.form.get('language').setValidators([Validators.required]);
			this.otherLanguage = true;
		}
	}

	closeOthers(field): void {
		if (field == 'language') {
			this.form.get('language').clearValidators();
			this.form.controls['language'].setValue('');
			this.form.get('language_id').setValidators([Validators.required]);
			this.otherLanguage = false;
		}

		this.submitted = false;
	}

}
