import { Component, OnInit } 			from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

@Component({
  selector: 'app-my-bookmark-detail',
  templateUrl: './my-bookmark-detail.component.html',
  styleUrls: ['./my-bookmark-detail.component.css']
})
export class MyBookmarkDetailComponent implements OnInit {

	json;
	bookmark;


  constructor(
  		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
	) { }

  ngOnInit() {
  	this.getBookmark();
  }

  public getBookmark(): void {
	  const id = +this.route.snapshot.paramMap.get('id');

	  this.apiService.getBookmark(id).subscribe(data => {

			if (data.status == false) {
				alert(data.message);
				this.router.navigate(['/bookmarks']);
				// this.goBack();
			} else {
				this.json 						= data;
				this.bookmark  					= data.data;
			}
		});
	}


	public apply(id: number): void {

		const param = {
			position_id: id
		};

		this.apiService.apply(param).subscribe(data => {

			if (data.status == true) {
				alert(data.message);
				this.router.navigate(['/bookmarks']);
				// HIDE APPLY BUTTON
			} else {
				alert(data.message);
			}
		});
	}

	public delete(): void {

		const id = +this.route.snapshot.paramMap.get('id');

		this.apiService.deleteBookmark(id).subscribe(data => {

			if (data.status == true) {
				alert(data.message);
				// HIDE APPLY BUTTON
				this.router.navigate(['/bookmarks']);
			} else {
				alert(data.message);
				this.router.navigate(['/bookmarks']);
			}
		});
	}
}
