import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';
import { NavigationExtras} 				from '@angular/router';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-my-posted-jobs',
	templateUrl: './my-posted-jobs.component.html',
	styleUrls: ['./my-posted-jobs.component.css']
})
export class MyPostedJobsComponent implements OnInit {

	posts: any;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
		) { }

	ngOnInit() {
		this.index();
	}

	index(): void {

		this.apiService.personalHirings().subscribe(data => {

			this.posts = data.data;
		});
	}

	edit(post: any): void {

		this.router.navigate(['/personal/i-am-hiring/' + post.id]);
	}

	activate(post: any): void {
		this.apiService.personalActivateHiring(post.id).subscribe(data => {

			if (data.status) {
				this.index();
			} else {
				alert(data.message);
			}
		});
	}

	deactivate(post: any): void {

		this.apiService.personalDeactivateHiring(post.id).subscribe(data => {

			if (data.status) {
				this.index();
			} else {
				alert(data.message);
			}
		});
	}

}
