import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  posts: any;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
		) { }

	ngOnInit() {
		this.index();
	}

	index(): void {

		this.apiService.corporateHiringsHistory().subscribe(data => {

			this.posts = data.data;
		});
	}

	activate(post: any): void {
		this.apiService.corporateActivateHiring(post.id).subscribe(data => {

			if (data.status) {
				this.index();
			} else {
				alert(data.message);
			}
		});
	}

	edit(post: any): void {

		this.router.navigate(['/corporate/edit-job/' + post.id]);
	}

}
