import { Component, OnInit } from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute} 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import {Globals} 			from '../globals';

declare function toggleMenu(type): any;


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	loggedIn: boolean;
	user: any;

	globals: any;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private global: Globals
	) { }

	ngOnInit() {

		this.isLoggedIn();
		this.verifyUser(this.router.url);
	}

	toggle(type): void {

		toggleMenu(type);
	}

	isLoggedIn(): void {

		if (localStorage.getItem('user')) {
			this.user = JSON.parse(localStorage.getItem('user'));
			this.loggedIn =  true;
		} else {
			this.loggedIn =  false;
		}
		this.globals = this.global;
	}

	logout(): void {

    	localStorage.removeItem('user');
		localStorage.removeItem('user.company');

		this.router.navigate(['/positions']);
	}

	verifyUser(link: any) {

		if (localStorage.getItem('user') !== null) {
			this.apiService.personalProfile().subscribe(data => {

				if (!data.status) {
						this.loggedIn =  false;
						this.logout();
						// alert(data.message);
				} else {
					this.loggedIn =  true;
					this.router.navigate([link]);
				}
			});
		}
	}
}
