import { Component, OnInit } from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

@Component({
	selector: 'app-company-posts',
	templateUrl: './company-posts.component.html',
	styleUrls: ['./company-posts.component.css']
})
export class CompanyPostsComponent implements OnInit {

	json;
	posts;
	postsLength;
	applicationCount = 0;

	user: any;
	token: string;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location) { }

	ngOnInit() {
		this.getUser();
	}

	getUser(): void {

		if (localStorage.getItem('user') !== null) {
			this.token = JSON.parse(localStorage.getItem('user')).token;

			if (!this.token) {
				this.router.navigate(['/']);
			}

			this.getPosts();
		} else {
			this.router.navigate(['/']);
		}

	}

	public getPosts(): void {

		this.applicationCount = 0;
		this.apiService.getCompanyPosts().subscribe(data => {

			window.scroll(0, 0);

			if (data.status == false) {
				alert(data.message);
			} else {
				this.json 					= data;
				this.posts  				= data.data.positions;
				this.postsLength  			= Object.keys(this.posts).length;


				for (let i = this.posts.length - 1; i >= 0; i--) {
					this.posts[i]['count'] 	= Object.keys(this.posts[i].applications).length;
					this.applicationCount	=  this.applicationCount + Object.keys(this.posts[i].applications).length;
				}

				// this.applicationsLength  	= Object.keys(this.posts.applications).length;
			}
		});
	}



}
