import { Component, OnInit } 	from '@angular/core';
import { ApiService } 			from  '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } 					from '@angular/common';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {Globals} 			from '../globals';

@Component({
	selector: 'app-inbox-messaging',
	templateUrl: './inbox-messaging.component.html',
	styleUrls: ['./inbox-messaging.component.css']
})
export class InboxMessagingComponent implements OnInit {

	user: any;

	json: any;
	messagesJson: any;

	threadId = null;
	userId = '';

	form: FormGroup;
	submitted = false;

	constructor(
		private apiService:  ApiService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private fb: FormBuilder,
		private globals: Globals
		) { }

	ngOnInit() {
		this.getUser();

		this.index();

		this.form = this.fb.group({
			content: 		['', [Validators.required]],
		});

	}

	get f() { return this.form.controls; }

	getUser(): void {

		if (localStorage.getItem('user') !== null) {
			this.user = JSON.parse(localStorage.getItem('user'));
			this.userId = this.user.id;
		} else {
			this.router.navigate(['/']);
		}

	}

	public index(): void {
		this.apiService.getMessageThreads().subscribe(data => {

			window.scroll(0, 0);

			if (data.status == false) {
				alert(data.message);
			} else {
				this.json 						= data;

				this.setGlobalMsgCount();
			}
		});
	}

	setGlobalMsgCount() {

		let count = 0;

		for (let i = 0; i < this.json.data.length; i++) {
			for (let j = 0; j < this.json.data[i].client_threads.length; j++) {
				if (this.json.data[i].client_threads[j].client_id !== this.userId) {
					count = count + (+this.json.data[i].client_threads[j].new_msg_count);
				}
			}
		}

		this.globals.newMessageCount = count;
	}

	public showMessages(id: any): void {

		this.threadId = id;

		this.apiService.getMessages(this.threadId).subscribe(data => {

			if (data.status == false) {
				alert(data.message);
			} else {
				this.messagesJson 				= data;
			}

			this.index();
		});

	}

	public postMessage(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.postMessage(this.form.value, this.threadId).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.submitted = false;
					this.showMessages(this.threadId);
				} else {
					alert(data.message);
				}
			});
		}
	}
}
