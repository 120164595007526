import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent implements OnInit {

  	user: any;
	company: any;
	token: string;
	experience: any;
	experiences: any;
	options: any;
	new = true;
	disable = false;

	form: FormGroup;
    submitted = false;

    otherIndustry = false;
    otherSpecialization = false;
    otherPositionLevel = false;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.index();

		this.form = this.fb.group({
            position_title: ['', [Validators.required]],
            company_name: ['', [Validators.required]],
            industry_id: ['', [Validators.required]],
            monthly_salary: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            start_date: ['', [Validators.required]],
            end_date: ['', ],
            present: [false, ],
            specialization_id: ['', [Validators.required]],
            role_id: ['', [Validators.required]],
            position_level_id: ['', [Validators.required]],
            description: ['', [Validators.required]],
            industry: ['', []],
            specialization: ['', []],
            position_level: ['', []]

        });
	}

	get f() { return this.form.controls; }

	getUser(): void {

		if (localStorage.getItem('user') !== null) {
			this.token = JSON.parse(localStorage.getItem('user')).token;

			if (!this.token) {
				this.router.navigate(['/']);
			}

			this.profile();
		} else {
			this.router.navigate(['/']);
		}

	}

	index(): void {

		this.apiService.personalExperienceOptions().subscribe(data => {

			this.options = data.data;
			this.getExperiences();
		});
	}

	profile(): void {

		// this.apiService.profile().subscribe(data => {

		// 	if(!data.status)
		// 	{
		// 		alert(data.message);
		// 		localStorage.removeItem('user')
		// 		this.router.navigate(['/positions']);

		// 	}

		// 	this.user 		= data.data;
		// 	this.company 	= data.data.company;

		// 	if(this.company)
		// 		localStorage.setItem('user.company', JSON.stringify(data.data.company));
		// });
	}

	create(): void {

		this.submitted = true;

		if (this.form.valid) {
			if (this.form.controls['present'].value === false && this.form.controls['end_date'].value === '') {
				alert('Either end date or present must be entered');
			} else {

				this.apiService.personalCreateExperience(this.form.value).subscribe(data => {

					if (data.status) {
						this.form.reset();
						this.submitted = false;
					}

					this.index();
					alert(data.message);
				});
			}
			
		}

	}

	getExperiences(): void {

		this.apiService.personalExperiences().subscribe(data => {
			this.experiences = data.data;
		});

	}

	edit(experience: any): void {

		window.scroll(0, 0);
		this.new = false;
		this.experience = experience;

		this.form.setValue({
		  position_title: experience.position_title,
		  company_name: experience.company_name,
		  monthly_salary: experience.monthly_salary,
		  start_date: experience.start_date,
		  end_date: experience.end_date,
		  present: experience.present,
		  role_id: experience.role_id,
		  description: experience.description,
		  industry_id: experience.industry_id,
		  industry: '',
		  specialization_id: experience.specialization_id,
		  specialization: '',
		  position_level_id: experience.position_level_id,
		  position_level: ''
		});
	}

	setNew(): void {

		this.new = true;
		this.form.reset();
		this.submitted = false;
		// this.form.controls['end_date'].enable();
		this.form.controls['present'].setValue(false);

		this.closeOthers('industry');
		this.closeOthers('specialization');
		this.closeOthers('position_level');
	}

	update(): void {

		this.submitted = true;

		if (this.form.valid) {

			if (this.form.controls['present'].value === false && this.form.controls['end_date'].value === '') {
				alert('Either end date or present must be entered');
			} else {
				this.apiService.personalUpdateExperience(this.form.value, this.experience.id).subscribe(data => {

					if (data.status) {
						this.form.reset();
					}

					this.setNew();
					this.index();
				});
			}

		}
	}


	delete(experience: any): void {

		this.apiService.personalDeleteExperience(experience.id).subscribe(data => {

			this.setNew();
			this.getExperiences();
		});
	}

	togglePresent(event) {
		if ( event.target.checked ) {
	    	this.form.controls['end_date'].disable();
	    	this.form.controls['end_date'].setValue('');
	    } else {
    		this.form.controls['end_date'].enable();
    		// this.form.controls['present'].setValue(0);
		}

	}

	other(value, field): void {
		if (field == 'industry' && value == 58) { // 7 == Others
			this.form.get('industry_id').clearValidators();
			this.form.controls['industry_id'].setValue('');
			this.form.get('industry').setValidators([Validators.required]);
			this.otherIndustry = true;
		} else if (field == 'specialization' && value == 67) { // 7 == Others
			this.form.get('specialization_id').clearValidators();
			this.form.controls['specialization_id'].setValue('');
			this.form.get('specialization').setValidators([Validators.required]);
			this.otherSpecialization = true;
		} else if (field == 'position_level' && value == 7) { // 7 == Others
			this.form.get('position_level_id').clearValidators();
			this.form.controls['position_level_id'].setValue('');
			this.form.get('position_level').setValidators([Validators.required]);
			this.otherPositionLevel = true;
		}
	}

	closeOthers(field): void {
		if (field == 'industry') {
			this.form.get('industry').clearValidators();
			this.form.controls['industry'].setValue('');
			this.form.get('industry_id').setValidators([Validators.required]);
			this.otherIndustry = false;
		} else if (field == 'specialization') {
			this.form.get('specialization').clearValidators();
			this.form.controls['specialization'].setValue('');
			this.form.get('specialization_id').setValidators([Validators.required]);
			this.otherSpecialization = false;
		} else if (field == 'position_level') {
			this.form.get('position_level').clearValidators();
			this.form.controls['position_level'].setValue('');
			this.form.get('position_level_id').setValidators([Validators.required]);
			this.otherPositionLevel = false;
		}
	}

}
