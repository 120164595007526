import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-sidebar',
  templateUrl: './corporate-sidebar.component.html',
  styleUrls: ['./corporate-sidebar.component.css']
})
export class CorporateSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
