import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
	selector: 'app-i-am-hiring',
	templateUrl: './i-am-hiring.component.html',
	styleUrls: ['./i-am-hiring.component.css']
})
export class IAmHiringComponent implements OnInit {

	user: any;
	company: any;
	token: string;
	lang: any;
	// langs: any;
	options: any;
	new = true;
	langSet: any;
	skillSet: any;

	languages: any[] = [];
	skills: any[] = [];

	form: FormGroup;
	submitted = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
		) { }

	ngOnInit() {
		this.index();

		this.form = this.fb.group({
            position_title: ['', [Validators.required]],
            location: ['', [Validators.required]],
            job_type: ['', [Validators.required]],
            specialization_id: ['', [Validators.required]],
            minimum_salary: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            maximum_salary: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            hourly_wages: ['', [Validators.pattern('^[0-9]*$')]],
            description: ['', [Validators.required]],
            qualification_id: ['', [Validators.required]],
            education_field_id: ['', [Validators.required]],
            nationality_id: ['', [Validators.required]],
            age: ['', [Validators.required]],
            experience_years: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            languages: ['', [Validators.required]],
            skills: ['', [Validators.required]]
        });

        this.setJobTypeValidators();
	}

	get f() { return this.form.controls; }


	setJobTypeValidators() {
		const minimum_salary = this.form.get('minimum_salary');
		const maximum_salary = this.form.get('maximum_salary');
		const hourly_wages = this.form.get('hourly_wages');

		this.form.get('job_type').valueChanges
		.subscribe(job_type => {

		if (job_type === 'casual') {
			hourly_wages.setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
			minimum_salary.setValidators([Validators.pattern('^[0-9]*$')]);
			maximum_salary.setValidators([Validators.pattern('^[0-9]*$')]);
		} else if (job_type === 'volunteer') {
			hourly_wages.setValidators([Validators.pattern('^[0-9]*$')]);
			minimum_salary.setValidators([Validators.pattern('^[0-9]*$')]);
			maximum_salary.setValidators([Validators.pattern('^[0-9]*$')]);
		} else {
			hourly_wages.setValidators([Validators.pattern('^[0-9]*$')]);
			minimum_salary.setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
			maximum_salary.setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);

		}

		hourly_wages.updateValueAndValidity();
		minimum_salary.updateValueAndValidity();
		maximum_salary.updateValueAndValidity();
		});
	}


	index(): void {

		this.apiService.personalHiringOptions().subscribe(data => {

			this.options = data.data;
			this.langSet = data.data.languages;
			this.skillSet = data.data.skills;
		});
	}

	create(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalCreateHiring(this.form.value).subscribe(data => {

				if (data.status) {
					this.submitted = false;
					this.form.reset();
					this.router.navigate(['/personal/posted-jobs']);
				} else {
					alert(data.message);
					this.router.navigate(['/personal/profile']);
				}
			});
		}
	}

	public onAddLang(item) {

		this.languages.push(item);
    }

	public onRemoveLang(item) {

		const index = this.languages.findIndex(d => d.value === item.value); //find index in your array
        this.languages.splice(index, 1); //remove element from array
    }

	public onAddSkill(item) {

		this.skills.push(item);
    }

	public onRemoveSkill(item) {

		const index = this.skills.findIndex(d => d.value === item.value); //find index in your array
        this.skills.splice(index, 1); //remove element from array
    }
}
