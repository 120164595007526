import { Component, OnInit, NgModule } 	from '@angular/core';
import { NgForm } 						from '@angular/forms';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';


@Component({
  selector: 'app-company-new',
  templateUrl: './company-new.component.html',
  styleUrls: ['./company-new.component.css']
})
export class CompanyNewComponent implements OnInit {

	user: any;
	company: any;
	token: string;

  constructor(
  		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
		) { }

  ngOnInit() {
  	this.getUser();
  }

  getUser(): void {

		if (localStorage.getItem('user') !== null) {
			this.token = JSON.parse(localStorage.getItem('user')).token;

			if (!this.token) {
				this.router.navigate(['/']);
			}

			this.profile();
		} else {
			this.router.navigate(['/']);
		}

	}

	profile(): void {

		// this.apiService.profile().subscribe(data => {

		// 	if(!data.status)
		// 	{
		// 		alert(data.message);
		// 		localStorage.removeItem('user')
		// 		this.router.navigate(['/positions']);

		// 	}

		// 	this.user 		= data.data;
		// 	this.company 	= data.data.company;

		// 	if(this.company)
		// 		localStorage.setItem('user.company', JSON.stringify(data.data.company));
		// });
	}

	save(form: NgForm): void {

		console.log(form.value);

		this.apiService.postCompany(form.value).subscribe(data => {
			if (data.status) {
				alert(data.message);
				// this.router.navigate(['/login']);

			} else {
				alert(data.message);
			}
		});
	}

}
