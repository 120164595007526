import { BrowserModule }                from '@angular/platform-browser';
import { NoopAnimationsModule }         from '@angular/platform-browser/animations';
import { Component, NgModule }          from '@angular/core';
import { FormsModule }                  from '@angular/forms';
import { HttpClientModule }             from  '@angular/common/http';
import { MomentModule }                 from 'angular2-moment';
import { LoadingBarHttpClientModule }   from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule }       from '@ngx-loading-bar/router';
import { RouteReuseStrategy }           from '@angular/router';
import { NgbModule }                    from '@ng-bootstrap/ng-bootstrap';
import { ngfModule, ngf }               from 'angular-file';
import { RouterModule, Routes }         from '@angular/router';

import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule }         from 'ng-recaptcha/forms';

import { QuillModule }                  from 'ngx-quill'

import { NgValidatorsModule }           from '@ng-validators/ng-validators';

import { ReactiveFormsModule }          from '@angular/forms';

import { TagInputModule }               from 'ngx-chips';
import { BrowserAnimationsModule }      from '@angular/platform-browser/animations';

import { SlickCarouselModule }          from 'ngx-slick-carousel';

import { AuthGuard }                    from './guards/auth.guard';
import { AdminGuardGuard }              from './guards/admin-guard.guard';


import { BsDropdownModule }             from 'ngx-bootstrap/dropdown';

import { AppComponent }                 from './app.component';
import { IndexComponent }               from './index/index.component';
import { HeaderComponent }              from './header/header.component';
import { FooterComponent }              from './footer/footer.component';
import { AuthComponent }                from './auth/auth.component';
import { AppRoutingModule }             from './app-routing.module';
import { PositionsComponent }           from './positions/positions.component';
import { PositionDetailComponent }      from './position-detail/position-detail.component';
import { SignupComponent }              from './signup/signup.component';
import { SigninComponent }              from './signin/signin.component';
import { ProfileComponent }             from './profile/profile.component';
import { MyApplicationsComponent }      from './my-applications/my-applications.component';
import { MyBookmarksComponent }         from './my-bookmarks/my-bookmarks.component';
import { ProfileSidebarComponent }      from './profile-sidebar/profile-sidebar.component';
import { MyBookmarkDetailComponent }    from './my-bookmark-detail/my-bookmark-detail.component';
import { MyApplicationDetailComponent } from './my-application-detail/my-application-detail.component';
import { PostsComponent }               from './posts/posts.component';
import { CompanyPostsComponent }        from './company-posts/company-posts.component';
import { PostNewComponent }             from './post-new/post-new.component';
import { CompanyNewComponent }          from './company-new/company-new.component';
import { CompanyProfileComponent }      from './company-profile/company-profile.component';
import { AboutMeComponent }             from './about-me/about-me.component';
import { ExperienceComponent }          from './experience/experience.component';
import { EducationComponent }           from './education/education.component';
import { SkillComponent }               from './skill/skill.component';
import { DocUploadComponent }           from './doc-upload/doc-upload.component';
import { PrivacySettingsComponent }     from './privacy-settings/privacy-settings.component';
import { CorporateSidebarComponent }    from './corporate-sidebar/corporate-sidebar.component';
import { TermsOfUseComponent }          from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent }       from './privacy-policy/privacy-policy.component';
import { HowItWorksComponent }          from './how-it-works/how-it-works.component';
import { ForEmployersComponent }        from './for-employers/for-employers.component';
import { FaqComponent }                 from './faq/faq.component';
import { JobsIWantComponent }           from './jobs-i-want/jobs-i-want.component';
import { IAmHiringComponent }           from './i-am-hiring/i-am-hiring.component';
import { CreateJobsComponent }          from './create-jobs/create-jobs.component';
import { JobsPostedComponent }          from './jobs-posted/jobs-posted.component';
import { HistoryComponent }             from './history/history.component';
import { LanguageComponent }            from './language/language.component';
import { MyPostedJobsComponent }        from './my-posted-jobs/my-posted-jobs.component';
import { IAmHiringEditComponent }       from './i-am-hiring-edit/i-am-hiring-edit.component';
import { AboutCompanyComponent }        from './about-company/about-company.component';
import { CompanyPostedJobsComponent }   from './company-posted-jobs/company-posted-jobs.component';
import { CompanyEditJobComponent }      from './company-edit-job/company-edit-job.component';
import { PostedJobDetailComponent }     from './posted-job-detail/posted-job-detail.component';
import { JobsIWantEditComponent }       from './jobs-i-want-edit/jobs-i-want-edit.component';
import { CorporateVerificationComponent }     from './corporate-verification/corporate-verification.component';
import { ResetPasswordComponent }       from './reset-password/reset-password.component';
import { MaaAdminComponent }            from './maa-admin/maa-admin.component';
import { MaaAdminSystemComponent }      from './maa-admin-system/maa-admin-system.component';
import { AccountSettingsComponent }     from './account-settings/account-settings.component';
import { AccountSidebarComponent }      from './account-sidebar/account-sidebar.component';
import { ReportIssueComponent }         from './report-issue/report-issue.component';
import { CompanyPostedJobComponent }    from './company-posted-job/company-posted-job.component';
import { InboxMessagingComponent }      from './inbox-messaging/inbox-messaging.component';
import { MaaAdminUserProfileComponent } from './maa-admin-user-profile/maa-admin-user-profile.component';
import { UserActivationComponent }      from './user-activation/user-activation.component';
import { ResetPasswordCompleteComponent }     from './reset-password-complete/reset-password-complete.component';
import { SearchCandidatesComponent }          from './search-candidates/search-candidates.component';
import { MaaAdminCorporateProfileComponent }  from './maa-admin-corporate-profile/maa-admin-corporate-profile.component';
import { SearchCandidatesProfileComponent }   from './search-candidates-profile/search-candidates-profile.component';

import { Globals }                     from './globals';
import { ReuseService }                from './reuse.service';
import { CustomRouteReuseStrategy }    from './cache-route-reuse.strategy';
import { SavedCandidatesComponent }    from './saved-candidates/saved-candidates.component';
import { CompanySubsidiariesComponent } from './company-subsidiaries/company-subsidiaries.component';
import { CompanySubsidiariesEditComponent } from './company-subsidiaries-edit/company-subsidiaries-edit.component';
import { MaaAdminCorporateSubsidiaryProfileComponent } from './maa-admin-corporate-subsidiary-profile/maa-admin-corporate-subsidiary-profile.component';
import { CorporateCollaboratorsComponent } from './corporate-collaborators/corporate-collaborators.component';
import { CorporateCollaboratedComponent } from './corporate-collaborated/corporate-collaborated.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    BsDropdownModule.forRoot(),
    MomentModule,
    NgbModule.forRoot(),
    ngfModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgValidatorsModule,
    ReactiveFormsModule,
    TagInputModule,
    BrowserAnimationsModule,
    QuillModule.forRoot({
        theme: 'snow',
    }),
    SlickCarouselModule,
  ],
  declarations: [
    AppComponent,
    IndexComponent,
    HeaderComponent,
    FooterComponent,
    AuthComponent,
    PositionsComponent,
    PositionDetailComponent,
    SignupComponent,
    SigninComponent,
    ProfileComponent,
    MyApplicationsComponent,
    MyBookmarksComponent,
    ProfileSidebarComponent,
    MyBookmarkDetailComponent,
    MyApplicationDetailComponent,
    PostsComponent,
    CompanyPostsComponent,
    PostNewComponent,
    CompanyNewComponent,
    CompanyProfileComponent,
    ExperienceComponent,
    AboutMeComponent,
    EducationComponent,
    SkillComponent,
    DocUploadComponent,
    PrivacySettingsComponent,
    CorporateSidebarComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    HowItWorksComponent,
    ForEmployersComponent,
    FaqComponent,
    JobsIWantComponent,
    IAmHiringComponent,
    CreateJobsComponent,
    JobsPostedComponent,
    HistoryComponent,
    LanguageComponent,
    MyPostedJobsComponent,
    IAmHiringEditComponent,
    AboutCompanyComponent,
    CompanyPostedJobsComponent,
    CompanyEditJobComponent,
    PostedJobDetailComponent,
    JobsIWantEditComponent,
    CorporateVerificationComponent,
    ResetPasswordComponent,
    MaaAdminComponent,
    MaaAdminSystemComponent,
    AccountSettingsComponent,
    AccountSidebarComponent,
    ReportIssueComponent,
    CompanyPostedJobComponent,
    InboxMessagingComponent,
    MaaAdminUserProfileComponent,
    UserActivationComponent,
    ResetPasswordCompleteComponent,
    SearchCandidatesComponent,
    MaaAdminCorporateProfileComponent,
    SearchCandidatesProfileComponent,
    SavedCandidatesComponent,
    CompanySubsidiariesComponent,
    CompanySubsidiariesEditComponent,
    MaaAdminCorporateSubsidiaryProfileComponent,
    CorporateCollaboratorsComponent,
    CorporateCollaboratedComponent,
  ],
  providers: [
      Globals,
      AuthGuard,
      AdminGuardGuard,
      {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
          siteKey: '6LegKH8UAAAAAEf8BQS-vQgdvgk93M6iggSEO6O0', //6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
        } as RecaptchaSettings,
      },
      ReuseService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }