import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
import { saveAs } from 'file-saver';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-posted-job',
  templateUrl: './company-posted-job.component.html',
  styleUrls: ['./company-posted-job.component.css']
})
export class CompanyPostedJobComponent implements OnInit {

  	post: any;
  	selectedType: String;
  	applicType: String;

	userId: null;
	jobId: any;

	advanced = false;
	submitted = false;
	saved = false;

	hideme: any[] = [];
	filterData: any;
	races: any[] = [];
	nationalities: any[] = [];
	specializations: any[] = [];
	position_roles: any[] = [];
	industries: any[] = [];
	qualifications: any[] = [];
	study_fields: any[] = [];
	languages: any[] = [];
	skills: any[] = [];

	form: FormGroup;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder

		) { }

	ngOnInit() {
		this.jobId = this.route.snapshot.paramMap.get('id');
		
		this.selectedType = 'pending';
		this.applicType = 'all';

		this.form = this.fb.group({
			gender: ['', ],
			min_age: ['', Validators.pattern('^[0-9]*$')],
			max_age: ['', Validators.pattern('^[0-9]*$')],
			min_salary: ['', Validators.pattern('^[0-9]*$')],
			max_salary: ['', Validators.pattern('^[0-9]*$')],
			min_years: ['', Validators.pattern('^[0-9]*$')],
			max_years: ['', Validators.pattern('^[0-9]*$')],
			last_updated: ['', ],
            races: [this.races, ],
            nationalities: [this.nationalities, ],
            specializations: [this.specializations, ],
            position_roles: [this.position_roles, ],
            industries: [this.industries, ],
            qualifications: [this.qualifications, ],
            study_fields: [this.study_fields, ],
            languages: [this.languages, ],
            skills: [this.skills, ],
            status: ['', ]
        });

        if (this.route.snapshot.params['status']) {
			this.selectedType 	= this.route.snapshot.params['status'];
        }
        if (this.route.snapshot.params['saved']) {
			this.applicType 	= 'saved';
        }

		this.index(this.selectedType, this.applicType);
	}

	get f() { return this.form.controls; }

	index(type: String, applicType: String): void {
		// const id = +this.route.snapshot.paramMap.get('id');

		this.hideme = [];

		this.apiService.corporateHiringByStatus(type, applicType, this.jobId).subscribe(data => {

			if (data.status) {
				this.post = data.data;
			} else {
				alert(data.message);
			}

			this.filterIndex();
		});
	}

	filterIndex() {
		this.apiService.corporateHIringFilterOptions().subscribe(data => {

			this.filterData = data['data'];
		});

	}

	filterApplicants(id) {
		this.submitted = true;

		if (this.form.valid) {
			this.advanced = false;

			this.form.controls['status'].setValue(this.selectedType);

			this.apiService.corporateFilterApplicants(this.form.value, id).subscribe(data => {

				if (data.status) {
					this.submitted 	= false;
					this.post 		= data.data;
				} else {
					alert(data.message);
				}
			});

			window.scroll(0, 0);
		}
	}

	clearFilter(): void {

		this.form.reset();
		this.races = [];
		this.nationalities = [];
		this.specializations = [];
		this.position_roles = [];
		this.industries = [];
		this.qualifications = [];
		this.study_fields = [];
		this.languages = [];
		this.skills = [];
	}

	onChangeAdd(item, type) {

		this[type].push(item);
    }

	onChangeRemove(item, type) {

		const index = this[type].findIndex(d => d.id === item.id); //find index in your array
        this[type].splice(index, 1); //remove element from array
    }

	selected(type: String): void {

		this.selectedType = type;
		this.index(type, this.applicType);
		this.form.controls['status'].setValue(type);
		this.hideme = [];
	}

	applicantsType(type: String): void {

		this.applicType = type;
		this.index(this.selectedType, this.applicType);
		this.form.controls['status'].setValue(this.selectedType);
		this.hideme = [];
	}

	toggleSearch(): void {

		if (this.advanced) {
			this.advanced = false;
		} else {
			this.advanced = true;
		}
	}

	respond(status: String, post: any): void {

		const params = {};
		params['status'] = status;

		this.apiService.corporateRespondApplication(params, post.id).subscribe(data => {

			if (data.status) {
				this.index(this.selectedType, this.applicType);
			} else {
				alert(data.message);
			}
		});
	}

	save(applicId, i): void {

		// const params = {};
		// params['user_id'] = userId;

		this.apiService.corporateSaveApplicant(applicId).subscribe(data => {


			if (data.status) {
				this.hideme[i] = !this.hideme[i];
				// this.index(this.selectedType);
			} else {
				alert(data.message);
			}
		});

	}

	remove(applicId, i): void {

		// const params = {};
		// params['user_id'] = userId;

		this.apiService.corporateRemoveApplicant(applicId).subscribe(data => {

			// this.hideme[] = this.hideme[].filter(obj => obj !== this.hideme[i]);

			if (data.status) {
				this.hideme[i] = !this.hideme[i];
				this.index(this.selectedType, this.applicType);
			} else {
				alert(data.message);
			}
		});

	}

	message(application: any): void {

		this.userId = application.user_id;

		const params = {};
		params['clientId'] = this.userId;

		this.apiService.createThread(params).subscribe(data => {

			if (data.status) {
				this.router.navigate(['/inbox-messaging']);
			} else {
				alert(data.message);
			}
		});
	}

	getResume(user: any): void {

		this.apiService.corporateGetApplicantResume(user.id).subscribe(data => {

				const blob = new Blob([data], {type: 'application/pdf'});
				if (blob.size > 2) {
					const file = window.URL.createObjectURL(blob);
					saveAs(file, 'resume.pdf');
				} else {
					alert('No Resume document uploaded previously');
				}
			});
	}

}
