import { Injectable } 						from '@angular/core';
import { HttpClient, HttpHeaders } 			from '@angular/common/http';
import { Observable, of } 					from 'rxjs';
import { catchError, map, tap } 			from 'rxjs/operators';


const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
	// headers: new HttpHeaders().set('A-Auth-Token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJsdW1lbi1qd3QiLCJzdWIiOjEsImlhdCI6MTUyOTM0NDA4NywiZXhwIjoxNTI5NDMwNDg3fQ.b8X2DVGMaRoFVj3plYaAQjia7Qh54J-PnNuoi-Ub3Eg'),
};

@Injectable({
	providedIn: 'root'
})

export class ApiService {

private BASE_URL  					=  'https://api.maax.jobs';
// private BASE_URL  					=  'https://10.71.226.90:8000';
// private BASE_URL  					=  'http://localhost:8000';
private INDEX_URL  					=  (this.BASE_URL + '/');

// ABOUT ME
private PERSONALPROFILE_URL					=  (this.BASE_URL + '/personal/profile');

private PERSONALABOUTMEOPTIONS_URL			=  (this.BASE_URL + '/personal/aboutMeOptions');
private PERSONALABOUTME_URL					=  (this.BASE_URL + '/personal/aboutMe');
private PERSONALABOUTMEIMAGE_URL			=  (this.BASE_URL + '/personal/aboutMe/image');
private PERSONALUPDATEABOUTME_URL			=  (this.BASE_URL + '/personal/aboutMe');

private PERSONALEXPERIENCEOPTIONS_URL		=  (this.BASE_URL + '/personal/experienceOptions');
private PERSONALCREATEEXPERIENCE_URL		=  (this.BASE_URL + '/personal/experience');
private PERSONALEXPERIENCES_URL				=  (this.BASE_URL + '/personal/experiences');
private PERSONALUPDATEEXPERIENCE_URL		=  (this.BASE_URL + '/personal/experience/');
private PERSONALDELETEEXPERIENCE_URL		=  (this.BASE_URL + '/personal/experience/');

private PERSONALEDUCATIONOPTIONS_URL		=  (this.BASE_URL + '/personal/educationOptions');
private PERSONALCREATEEDUCATION_URL			=  (this.BASE_URL + '/personal/education');
private PERSONALEDUCATIONS_URL				=  (this.BASE_URL + '/personal/educations');
private PERSONALUPDATEEDUCATION_URL			=  (this.BASE_URL + '/personal/education/');
private PERSONALDELETEEDUCATION_URL			=  (this.BASE_URL + '/personal/education/');

private PERSONALSKILLOPTIONS_URL			=  (this.BASE_URL + '/personal/skillOptions');
private PERSONALCREATESKILL_URL				=  (this.BASE_URL + '/personal/skill');
private PERSONALSKILLS_URL					=  (this.BASE_URL + '/personal/skills');
private PERSONALUPDATESKILL_URL				=  (this.BASE_URL + '/personal/skill/');
private PERSONALDELETESKILL_URL				=  (this.BASE_URL + '/personal/skill/');

private PERSONALLANGUAGEOPTIONS_URL			=  (this.BASE_URL + '/personal/languageOptions');
private PERSONALCREATELANGUAGE_URL			=  (this.BASE_URL + '/personal/language');
private PERSONALLANGUAGES_URL				=  (this.BASE_URL + '/personal/languages');
private PERSONALUPDATELANGUAGE_URL			=  (this.BASE_URL + '/personal/language/');
private PERSONALDELETELANGUAGE_URL			=  (this.BASE_URL + '/personal/language/');

private PERSONALAPPLICATIONS_URL			=  (this.BASE_URL + '/personal/applications');
private PERSONALAPPLICATION_URL				=  (this.BASE_URL + '/personal/applications/');
private PERSONALAPPLICATIONDELETE_URL		=  (this.BASE_URL + '/personal/applications/');

private PERSONALBOOKMARKS_URL				=  (this.BASE_URL + '/personal/bookmarks');
private PERSONALBOOKMARK_URL				=  (this.BASE_URL + '/personal/bookmarks/');
private PERSONALBOOKMARKDELETE_URL			=  (this.BASE_URL + '/personal/bookmarks/');

private PERSONALHIRINGOPTIONS_URL			=  (this.BASE_URL + '/personal/hiringOptions');
private PERSONALCREATEHIRING_URL			=  (this.BASE_URL + '/personal/hiring');
private PERSONALHIRINGS_URL					=  (this.BASE_URL + '/personal/hirings');
private PERSONALHIRING_URL					=  (this.BASE_URL + '/personal/hiring/');
private PERSONALUPDATEHIRING_URL			=  (this.BASE_URL + '/personal/hiring/');
private PERSONALDELETEHIRING_URL			=  (this.BASE_URL + '/personal/hiring/');
private PERSONALHIRINGACTIVATE_URL			=  (this.BASE_URL + '/personal/hiring/activate/');
private PERSONALHIRINGDEACTIVATE_URL		=  (this.BASE_URL + '/personal/hiring/deactivate/');
private PERSONALHIRINGRESPOND_URL			=  (this.BASE_URL + '/personal/hiring/respond/');

private PERSONALSEEKINGOPTIONS_URL			=  (this.BASE_URL + '/personal/seekingOptions');
private PERSONALCREATESEEKING_URL			=  (this.BASE_URL + '/personal/seeking');
private PERSONALSEEKINGS_URL				=  (this.BASE_URL + '/personal/seekings');
private PERSONALSEEKING_URL					=  (this.BASE_URL + '/personal/seeking/');
private PERSONALUPDATESEEKING_URL			=  (this.BASE_URL + '/personal/seeking/');
private PERSONALDELETSEEKING_URL			=  (this.BASE_URL + '/personal/seeking/');
private PERSONALSEEKINGACTIVATE_URL			=  (this.BASE_URL + '/personal/seeking/activate/');
private PERSONALSEEKINGDEACTIVATE_URL		=  (this.BASE_URL + '/personal/seeking/deactivate/');

private PERSONALDOCS_URL					=  (this.BASE_URL + '/personal/docs');
private PERSONALDOCRESUME_URL				=  (this.BASE_URL + '/personal/docs/resume');
private PERSONALDOCVIDEO_URL				=  (this.BASE_URL + '/personal/docs/video');

private PERSONALPRIVACY_URL					=  (this.BASE_URL + '/personal/privacy');
private PERSONALUPDATEPRIVACY_URL			=  (this.BASE_URL + '/personal/privacy');

private PERSONALGETAPPLICANTRESUME_URL		=  (this.BASE_URL + '/personal/applicant/getResume/');
private PERSONALHIRINGFILTEROPTIONS_URL		=  (this.BASE_URL + '/personal/filterApplicants/options');
private PERSONALHIRINGFILTER_URL			=  (this.BASE_URL + '/personal/filterApplicants/');






// CORPORATE
private CORPORATEPROFILE_URL				=  (this.BASE_URL + '/corporate/profile');

private CORPORATEDOCS_URL					=  (this.BASE_URL + '/corporate/docs')	;
private CORPORATEDOCSSM_URL					=  (this.BASE_URL + '/corporate/docs/ssm');
private CORPORATEDOCFORM9_URL				=  (this.BASE_URL + '/corporate/docs/form9');

private CORPORATEABOUTCOMPANYOPTIONS_URL	=  (this.BASE_URL + '/corporate/aboutCompanyOptions');
private CORPORATEABOUTCOMPANY_URL			=  (this.BASE_URL + '/corporate/aboutCompany');
private CORPORATEABOUTCOMPANYIMAGE_URL		=  (this.BASE_URL + '/corporate/aboutCompany/image');
private CORPORATEUPDATEABOUTCOMPANY_URL		=  (this.BASE_URL + '/corporate/aboutCompany');


private CORPORATEABOUTCOMPANYSUBSIDIARY_URL	=  (this.BASE_URL + '/corporate/companySubsidiaries');
private CORPORATECREATECOMPANYSUBSIDIARY_URL=  (this.BASE_URL + '/corporate/companySubsidiary');
private CORPORATECOMPANYSUBSIDIARYIMAGE_URL	=  (this.BASE_URL + '/corporate/companySubsidiary/image');
private CORPORATECOMPANYSUBSIDIARY_URL		=  (this.BASE_URL + '/corporate/companySubsidiaries/');
private CORPORATEUPDATEABOUTSUBSIDIARY_URL	=  (this.BASE_URL + '/corporate/companyUpdateSubsidiary/');
private CORPORATEDELETESUBSIDIARY_URL		=  (this.BASE_URL + '/corporate/companySubsidiaries/');

private CORPORATECOLLABORATORS_URL			=  (this.BASE_URL + '/corporate/collaborators');
private CORPORATEADDCOLLABORATOR_URL		=  (this.BASE_URL + '/corporate/collaborator');
private CORPORATEREMOVECOLLABORATOR_URL		=  (this.BASE_URL + '/corporate/collaborator/');
private CORPORATETOGGLECOLLABORATOR_URL		=  (this.BASE_URL + '/corporate/collaborator/');

// private CORPORATEGETCOMPANYSUBSIDIARY_URL	=  (this.BASE_URL + '/corporate/companySubsidiaries/');
// private CORPORATEUPDATECOMPANYSUBSIDIARY_URL=  (this.BASE_URL + '/corporate/companySubsidiaries/');


private CORPORATEHIRINGOPTIONS_URL			=  (this.BASE_URL + '/corporate/hiringOptions');
private CORPORATECREATEHIRING_URL			=  (this.BASE_URL + '/corporate/hiring');
private CORPORATEHIRINGS_URL				=  (this.BASE_URL + '/corporate/hirings');
private CORPORATEHIRINGSHISTORY_URL			=  (this.BASE_URL + '/corporate/hiringsHistory');
private CORPORATEHIRING_URL					=  (this.BASE_URL + '/corporate/hiring/');
private CORPORATEUPDATEHIRING_URL			=  (this.BASE_URL + '/corporate/hiring/');
// private CORPORATEDELETEHIRING_URL			=  (this.BASE_URL + '/corporate/hiring/');
private CORPORATEHIRINGACTIVATE_URL			=  (this.BASE_URL + '/corporate/hiring/activate/');
private CORPORATEHIRINGDEACTIVATE_URL		=  (this.BASE_URL + '/corporate/hiring/deactivate/');
private CORPORATEHIRINGRESPOND_URL			=  (this.BASE_URL + '/corporate/hiring/respond/');
private CORPORATESAVEAPPLICANT_URL			=  (this.BASE_URL + '/corporate/hiring/applications/');
private CORPORATEREMOVEAPPLICANT_URL		=  (this.BASE_URL + '/corporate/hiring/applications/');

private CORPORATECOLLABORATED_URL			=  (this.BASE_URL + '/corporate/collaborated');

private CORPORATEGETAPPLICANTRESUME_URL		=  (this.BASE_URL + '/corporate/applicant/getResume/');
private CORPORATEHIRINGFILTEROPTIONS_URL	=  (this.BASE_URL + '/corporate/filterApplicants/options');
private CORPORATEHIRINGFILTER_URL			=  (this.BASE_URL + '/corporate/filterApplicants/');

private CORPORATESEARCHCANDIDATESOPTIONS_URL =  (this.BASE_URL + '/corporate/searchCandidates/options');
private CORPORATESEARCHCANDIDATES_URL		=  (this.BASE_URL + '/corporate/searchCandidates');
private CORPORATESAVEDCANDIDATES_URL		=  (this.BASE_URL + '/corporate/savedCandidates/');
private CORPORATEREMOVECANDIDATE_URL		=  (this.BASE_URL + '/corporate/removeCandidate');
private CORPORATEGETTAGS_URL				=  (this.BASE_URL + '/corporate/tags');
private CORPORATEADDTAG_URL					=  (this.BASE_URL + '/corporate/tag');
private CORPORATESAVECANDIDATE_URL			=  (this.BASE_URL + '/corporate/candidate');
private CORPORATEGETCANDIDATERESUME_URL		=  (this.BASE_URL + '/corporate/candidate/getResume/');
private CORPORATEGETCANDIDATEPROFILE_URL	=  (this.BASE_URL + '/corporate/candidate/getProfile/');


// ADMIN
private ADMINCORPORATEREQUESTS_URL			=  (this.BASE_URL + '/admin/corporate/requests');
private ADMINCORPORATEVERIFY_URL			=  (this.BASE_URL + '/admin/corporate/verify/');
private ADMINCORPORATESSM_URL				=  (this.BASE_URL + '/admin/corporate/docs/ssm/');
private ADMINCORPORATEFORM9_URL				=  (this.BASE_URL + '/admin/corporate/docs/form9/');

private ADMINGETCRM_URL						=  (this.BASE_URL + '/admin/crm/');
private ADMINADDCRM_URL						=  (this.BASE_URL + '/admin/crm');
private ADMINUPDATECRM_URL					=  (this.BASE_URL + '/admin/crm/');
private ADMINDELETECRM_URL					=  (this.BASE_URL + '/admin/crm/');

private ADMINCORPORATESEARCH_URL			=  (this.BASE_URL + '/admin/corporate/search');
private ADMINCORPORATEPROFILE_URL			=  (this.BASE_URL + '/admin/corporate/profile/');
private ADMINCORPORATESUBSIDIARYPROFILE_URL	=  (this.BASE_URL + '/admin/corporate/subsidiary/profile/');
private ADMINCORPORATEBAN_URL				=  (this.BASE_URL + '/admin/corporate/ban/');
private ADMINCORPORATEUNBAN_URL				=  (this.BASE_URL + '/admin/corporate/unban/');

private ADMINUSERSEARCH_URL					=  (this.BASE_URL + '/admin/user/search');
private ADMINUSERPROFILE_URL				=  (this.BASE_URL + '/admin/user/profile/');
private ADMINUSERBAN_URL					=  (this.BASE_URL + '/admin/user/ban/');
private ADMINUSERUNBAN_URL					=  (this.BASE_URL + '/admin/user/unban/');

private ADMINUSERSREPORTOPTIONS_URL			=  (this.BASE_URL + '/admin/report/users/options');
private ADMINUSERSREPORT_URL				=  (this.BASE_URL + '/admin/report/users');

private ADMINSYSTEMISSUES_URL				=  (this.BASE_URL + '/admin/system/issues');
private ADMINSYSTEMISSUERESOLVE_URL			=  (this.BASE_URL + '/admin/system/issueResolve/');

private ADMINSYSTEMENQUIRIES_URL			=  (this.BASE_URL + '/admin/system/enquiries');
private ADMINSYSTEMENQUIRYRESOLVE_URL		=  (this.BASE_URL + '/admin/system/enquiryResolve/');

private ADMINUSERS_URL						=  (this.BASE_URL + '/admin/users');
private ADMINUADDUSER_URL					=  (this.BASE_URL + '/admin/user');
private ADMINUREMOVEUSER_URL				=  (this.BASE_URL + '/admin/users/');

// USER
private USERCHANGEPASSWORD_URL				=  (this.BASE_URL + '/user/changePassword');


// SEARCH
private SEARCHJOB_URL						=  (this.BASE_URL + '/search/jobs');
private SEARCHJOBOPTIONS_URL				=  (this.BASE_URL + '/search/jobs/options');

// MESSAGES
private MESSAGETHREADS_URL					=  (this.BASE_URL + '/threads');
private CREATETHREAD_URL					=  (this.BASE_URL + '/thread');
private MESSAGES_URL						=  (this.BASE_URL + '/messages/');
private POSTMESSAGE_URL						=  (this.BASE_URL + '/message/');






// POSITIONS --------------------------
private POSITIONS_URL				=  (this.BASE_URL + '/positions');
private POSITION_URL				=  (this.BASE_URL + '/positions/');
private POSITIONSHARE_URL			=  (this.BASE_URL + '/positions/share/');
private POSITIONAPPLY_URL			=  (this.BASE_URL + '/position/apply');
private POSITIONSAVE_URL			=  (this.BASE_URL + '/position/save');

private REGISTER_URL				=  (this.BASE_URL + '/register');
private ACTIVATE_URL				=  (this.BASE_URL + '/user/activate');
private LOGIN_URL					=  (this.BASE_URL + '/login');
private RESETPASSWORD_URL			=  (this.BASE_URL + '/user/resetPassword');
private COMPLETERESETPASSWORD_URL	=  (this.BASE_URL + '/user/completeResetPassword');
private ENQUIRIES_URL				=  (this.BASE_URL + '/enquire');

private SYSTEMREPORTISSUE_URL		=  (this.BASE_URL + '/system/issue');
// private PROFILE_URL					=  (this.BASE_URL + '/user/profile');




private APPLY_URL					=  (this.BASE_URL + '/application');
private BOOKMARK_URL				=  (this.BASE_URL + '/bookmark');
private BOOKMARKS_URL				=  (this.BASE_URL + '/bookmarks');
private GETBOOKMARK_URL				=  (this.BASE_URL + '/bookmarks/');
private DELBOOKMARK_URL				=  (this.BASE_URL + '/bookmarks/');
private DELAPPLICATION_URL			=  (this.BASE_URL + '/applications/');
private GETAPPLICATION_URL			=  (this.BASE_URL + '/applications/');
private POSTJOB_URL					=  (this.BASE_URL + '/position');
private GETOPTIONS_URL				=  (this.BASE_URL + '/options');
private CREATECOMPANY_URL			=  (this.BASE_URL + '/company/create');
private COMPANYPROFILE_URL			=  (this.BASE_URL + '/company/profile');
private UPDATECOMPANY_URL			=  (this.BASE_URL + '/company/update');
private COMPANYPOSTS_URL			=  (this.BASE_URL + '/company/posts');



constructor(private http:  HttpClient) { }

	// headers(): HttpHeaders {
	// 	return new HttpHeaders().set('A-Auth-Token', this.getToken())
	//         .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
	//         .append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Content-Type, Authorization, X-Requested-With, A-Auth-Token')
	//         // .append('Access-Control-Allow-Origin', 'http://localhost:4200')
	//         // .append('Content-Type', 'application/json');
	//         // .append('Authorization', "<<my token>>");
	//         ;
	// }
	headers(): HttpHeaders {
		return new HttpHeaders()
			.set('A-Auth-Token', this.getToken())
	        // .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
	        // .append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Content-Type, Authorization, X-Requested-With, A-Auth-Token')
	        // .append('Access-Control-Allow-Origin', 'http://localhost:4200')
	        .append('Content-Type', 'application/json');
	        // .append('Authorization', "<<my token>>");

	}

	fileHeaders(): HttpHeaders {
		return new HttpHeaders()
			.set('A-Auth-Token', this.getToken())
	        // .append('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH,OPTIONS')
	        // .append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Content-Type, Authorization, X-Requested-With, A-Auth-Token')
	        // .append('Access-Control-Allow-Origin', 'http://localhost:4200')
	        .append('Accept', 'video/*')
	        .append('Accept', 'application/pdf');

	}

	getToken(): string {
		if (localStorage.getItem('user') !== null) {
			const json = JSON.parse(localStorage.getItem('user'));

			if (!json.token) {
				return '';
			}

			return json.token;
		}
		return null;
	}

	// LANDING PAGE
	getIndex(): Observable<any> {
		if (this.getToken() !== null) {
			return this.http.get(this.INDEX_URL, { headers: this.headers() })
			  	.pipe(
			    	catchError(this.handleError<JSON>('profile'))
			  	);
		} else {
			return  this.http.get(this.INDEX_URL);
		}
	}

	// AUTHETICATION
	register(param: any): Observable<any> {
	  return this.http.post(this.REGISTER_URL, param)
	  	.pipe(
	    	catchError(this.handleError<any>('register'))
  		);
	}
	enquire(param: any): Observable<any> {
	  return this.http.post(this.ENQUIRIES_URL, param)
	  	.pipe(
	    	catchError(this.handleError<any>('register'))
  		);
	}

	activate(code: any): Observable<any> {
	  return this.http.get(this.ACTIVATE_URL + '?code=' + code)
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	login(param: any): Observable<any> {
	  return this.http.post(this.LOGIN_URL, param)
	  	.pipe(
	    	catchError(this.handleError<JSON>('login'))
	  	);
	}

	resetPassword(param: any): Observable<any> {
	  return this.http.post(this.RESETPASSWORD_URL, param)
	  	.pipe(
	    	catchError(this.handleError<JSON>('resetPassword'))
	  	);
	}

	resetPasswordComplete(code: any, email: any): Observable<any> {
	  return this.http.get(this.COMPLETERESETPASSWORD_URL + '?code=' + code + '&email=' + email)
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}








	// PERSONAL ----------
	personalProfile(): Observable<any> {
	  return this.http.get(this.PERSONALPROFILE_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalAboutMeOptions(): Observable<any> {
	  return this.http.get(this.PERSONALABOUTMEOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalAboutMe(): Observable<any> {
	  return this.http.get(this.PERSONALABOUTME_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalImage(param: FormData) {

		return this.http.post(this.PERSONALABOUTMEIMAGE_URL, param, { headers: this.fileHeaders() })
		.pipe(
	    	catchError(this.handleError<JSON>('aboutmeimage'))
	  	);
  	}

	personalGetImage() {

		return this.http.get(this.PERSONALABOUTMEIMAGE_URL, { headers: this.headers(), responseType: 'blob' });
  	}

	personalUpdateAboutMe(param: any): Observable<any> {
	  return this.http.patch(this.PERSONALUPDATEABOUTME_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	// EXPERIENCES
	personalExperienceOptions(): Observable<any> {
	  return this.http.get(this.PERSONALEXPERIENCEOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalCreateExperience(param: any): Observable<any> {
	  return this.http.post(this.PERSONALCREATEEXPERIENCE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalExperiences(): Observable<any> {
	  return this.http.get(this.PERSONALEXPERIENCES_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalUpdateExperience(param, id): Observable<any> {
	  return this.http.patch(this.PERSONALUPDATEEXPERIENCE_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalDeleteExperience(id: any): Observable<any> {
	  return this.http.delete(this.PERSONALDELETEEXPERIENCE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	// EDUCATIONS
	personalEducationOptions(): Observable<any> {
	  return this.http.get(this.PERSONALEDUCATIONOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalCreateEducation(param: any): Observable<any> {
	  return this.http.post(this.PERSONALCREATEEDUCATION_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalEducations(): Observable<any> {
	  return this.http.get(this.PERSONALEDUCATIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalUpdateEducation(param, id): Observable<any> {
	  return this.http.patch(this.PERSONALUPDATEEDUCATION_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalDeleteEducation(id: any): Observable<any> {
	  return this.http.delete(this.PERSONALDELETEEDUCATION_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	// SKILLS
	personalSkillOptions(): Observable<any> {
	  return this.http.get(this.PERSONALSKILLOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalCreateSkill(param: any): Observable<any> {
	  return this.http.post(this.PERSONALCREATESKILL_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalSkills(): Observable<any> {
	  return this.http.get(this.PERSONALSKILLS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalUpdateSkill(param, id): Observable<any> {
	  return this.http.patch(this.PERSONALUPDATESKILL_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalDeleteSkill(id: any): Observable<any> {
	  return this.http.delete(this.PERSONALDELETESKILL_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	// LANGUAGES
	personalLanguageOptions(): Observable<any> {
	  return this.http.get(this.PERSONALLANGUAGEOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalCreateLanguage(param: any): Observable<any> {
	  return this.http.post(this.PERSONALCREATELANGUAGE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalLanguages(): Observable<any> {
	  return this.http.get(this.PERSONALLANGUAGES_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalUpdateLanguage(param, id): Observable<any> {
	  return this.http.patch(this.PERSONALUPDATELANGUAGE_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalDeleteLanguage(id: any): Observable<any> {
	  return this.http.delete(this.PERSONALDELETELANGUAGE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	// PRIVACY
	personalPrivacy(): Observable<any> {
	  return this.http.get(this.PERSONALPRIVACY_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalUpdatePrivacy(param): Observable<any> {
	  return this.http.patch(this.PERSONALUPDATEPRIVACY_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	// JOBS
	personalApplications(): Observable<any> {
	  return this.http.get(this.PERSONALAPPLICATIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('bookmarks'))
	  	);
	}

	personalApplicationDelete(id: number): Observable<any> {
	  return this.http.delete(this.PERSONALAPPLICATIONDELETE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('deleteApplication'))
	  	);
	}

	// JOBS
	personalBookmarks(): Observable<any> {
	  return this.http.get(this.PERSONALBOOKMARKS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('bookmarks'))
	  	);
	}

	personalBookmarkDelete(id: number): Observable<any> {
	  return this.http.delete(this.PERSONALBOOKMARKDELETE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('deleteApplication'))
	  	);
	}

	// I AM HIRING
	personalHiringOptions(): Observable<any> {
	  return this.http.get(this.PERSONALHIRINGOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalCreateHiring(param: any): Observable<any> {
	  return this.http.post(this.PERSONALCREATEHIRING_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalHirings(): Observable<any> {
	  return this.http.get(this.PERSONALHIRINGS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalHiring(id): Observable<any> {
	  return this.http.get(this.PERSONALHIRING_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalHiringByStatus(status, id): Observable<any> {
	  return this.http.get(this.PERSONALHIRING_URL + id + '?status=' + status, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalUpdateHiring(param, id): Observable<any> {
	  return this.http.patch(this.PERSONALUPDATEHIRING_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalActivateHiring(id: any): Observable<any> {
	  return this.http.patch(this.PERSONALHIRINGACTIVATE_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalDeactivateHiring(id): Observable<any> {
	  return this.http.patch(this.PERSONALHIRINGDEACTIVATE_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalRespondApplication(params, id): Observable<any> {
	  return this.http.patch(this.PERSONALHIRINGRESPOND_URL + id, params, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	// I AM SEEKING
	personalSeekingOptions(): Observable<any> {
	  return this.http.get(this.PERSONALSEEKINGOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalCreateSeeking(param: any): Observable<any> {
	  return this.http.post(this.PERSONALCREATESEEKING_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalSeekings(): Observable<any> {
	  return this.http.get(this.PERSONALSEEKINGS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalSeeking(id: any): Observable<any> {
	  return this.http.get(this.PERSONALSEEKING_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalUpdateSeeking(param, id): Observable<any> {
	  return this.http.patch(this.PERSONALUPDATESEEKING_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalActivateSeeking(id: any): Observable<any> {
	  return this.http.patch(this.PERSONALSEEKINGACTIVATE_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalDeactivateSeeking(id): Observable<any> {
	  return this.http.patch(this.PERSONALSEEKINGDEACTIVATE_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalDeleteSeeking(id): Observable<any> {
	  return this.http.delete(this.PERSONALDELETSEEKING_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalDocuments() {
		return this.http.get(this.PERSONALDOCS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	personalUploadResume(param: FormData) { //line 3

		return this.http.post(this.PERSONALDOCRESUME_URL, param, { headers: this.fileHeaders() })
		.pipe(
	    	catchError(this.handleError<JSON>('aboutmeimage'))
	  	);
  	}

  	personalGetResume() {

		return this.http.get(this.PERSONALDOCRESUME_URL, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

  	personalUploadVideo(param: FormData) { //line 3

		return this.http.post(this.PERSONALDOCVIDEO_URL, param, { headers: this.fileHeaders() })
		.pipe(
	    	catchError(this.handleError<JSON>('aboutmeimage'))
	  	);
  	}

  	personalGetVideo() {

		return this.http.get(this.PERSONALDOCVIDEO_URL, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

  	personalGetApplicantResume(id: any) {

		return this.http.get(this.PERSONALGETAPPLICANTRESUME_URL + id, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

  	personalHIringFilterOptions() {
  		return this.http.get(this.PERSONALHIRINGFILTEROPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
  	}

  	personalFilterApplicants(param, id): Observable<any> {
		return this.http.post(this.PERSONALHIRINGFILTER_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}





	// CORPORATE --------------------------------------
	corporateProfile(): Observable<any> {
	  return this.http.get(this.CORPORATEPROFILE_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateDocuments() {
		return this.http.get(this.CORPORATEDOCS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateUploadSSM(param: FormData) { //line 3

		return this.http.post(this.CORPORATEDOCSSM_URL, param, { headers: this.fileHeaders() })
		.pipe(
	    	catchError(this.handleError<JSON>('aboutmeimage'))
	  	);
  	}

  	corporateGetSSM() {

		return this.http.get(this.CORPORATEDOCSSM_URL, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

  	corporateUploadForm9(param: FormData) { //line 3

		return this.http.post(this.CORPORATEDOCFORM9_URL, param, { headers: this.fileHeaders() })
		.pipe(
	    	catchError(this.handleError<JSON>('aboutmeimage'))
	  	);
  	}

  	corporateGetForm9() {

		return this.http.get(this.CORPORATEDOCFORM9_URL, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

	corporateAboutCompanyOptions(): Observable<any> {
	  return this.http.get(this.CORPORATEABOUTCOMPANYOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateAboutCompany(): Observable<any> {
	  return this.http.get(this.CORPORATEABOUTCOMPANY_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateAboutCompanySubsidiaries(): Observable<any> {
	  return this.http.get(this.CORPORATEABOUTCOMPANYSUBSIDIARY_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateCompanySubsidiary(id): Observable<any> {
	  return this.http.get(this.CORPORATECOMPANYSUBSIDIARY_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('corporateCompanySubsidiary'))
	  	);
	}

	corporateImage(param: FormData) { //line 3

		return this.http.post(this.CORPORATEABOUTCOMPANYIMAGE_URL, param, { headers: this.fileHeaders() })
		.pipe(
	    	catchError(this.handleError<JSON>('aboutmeimage'))
	  	);
  	}

	corporateSubsidiaryImage(param: FormData) { //line 3

		return this.http.post(this.CORPORATECOMPANYSUBSIDIARYIMAGE_URL, param, { headers: this.fileHeaders() })
		.pipe(
	    	catchError(this.handleError<JSON>('aboutmeimage'))
	  	);
  	}

  	corporateGetImage() {

		return this.http.get(this.CORPORATEABOUTCOMPANYIMAGE_URL, { headers: this.headers(), responseType: 'blob' });
  	}

	corporateUpdateAboutCompany(param: any): Observable<any> {
	  return this.http.patch(this.CORPORATEUPDATEABOUTCOMPANY_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateUpdateAboutSubsidiary(param: any, id): Observable<any> {
	  return this.http.patch(this.CORPORATEUPDATEABOUTSUBSIDIARY_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateCreateCompanySubsidiary(param: any): Observable<any> {
	  return this.http.post(this.CORPORATECREATECOMPANYSUBSIDIARY_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateDeleteSubsidiary(id: any): Observable<any> {
	  return this.http.delete(this.CORPORATEDELETESUBSIDIARY_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	// COMPANY HIRING
	corporateHiringOptions(): Observable<any> {
	  return this.http.get(this.CORPORATEHIRINGOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateCreateHiring(param: any): Observable<any> {
	  return this.http.post(this.CORPORATECREATEHIRING_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateHirings(): Observable<any> {
	  return this.http.get(this.CORPORATEHIRINGS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateHiringsHistory(): Observable<any> {
	  return this.http.get(this.CORPORATEHIRINGSHISTORY_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateHiring(id: any): Observable<any> {
	  return this.http.get(this.CORPORATEHIRING_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateHiringByStatus(status, applicType, id): Observable<any> {
	  return this.http.get(this.CORPORATEHIRING_URL + id + '?status=' + status + '&type=' + applicType, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateUpdateHiring(param, id): Observable<any> {
	  return this.http.patch(this.CORPORATEUPDATEHIRING_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateActivateHiring(id: any): Observable<any> {
	  return this.http.patch(this.CORPORATEHIRINGACTIVATE_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateDeactivateHiring(id): Observable<any> {
	  return this.http.patch(this.CORPORATEHIRINGDEACTIVATE_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateCollaborated(): Observable<any> {
	  return this.http.get(this.CORPORATECOLLABORATED_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateRespondApplication(params, id): Observable<any> {
	  return this.http.patch(this.CORPORATEHIRINGRESPOND_URL + id, params, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateSaveApplicant(applicId): Observable<any> {
	// console.log(this.CORPORATESAVEAPPLICANT1_URL + id + this.CORPORATESAVEAPPLICANT2_URL);
	  return this.http.post(this.CORPORATESAVEAPPLICANT_URL + applicId, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}
	corporateRemoveApplicant(applicId): Observable<any> {
	// console.log(this.CORPORATESAVEAPPLICANT1_URL + id + this.CORPORATESAVEAPPLICANT2_URL);
	  return this.http.delete(this.CORPORATEREMOVEAPPLICANT_URL + applicId, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateGetApplicantResume(id: any) {

		return this.http.get(this.CORPORATEGETAPPLICANTRESUME_URL + id, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

  	corporateHIringFilterOptions() {
  		return this.http.get(this.CORPORATEHIRINGFILTEROPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
  	}

  	corporateFilterApplicants(param, id): Observable<any> {
		return this.http.post(this.CORPORATEHIRINGFILTER_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

  	corporateSearchCandidatesOptions() {
  		return this.http.get(this.CORPORATESEARCHCANDIDATESOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
  	}

  	corporateSearchCandidates(param): Observable<any> {
		return this.http.post(this.CORPORATESEARCHCANDIDATES_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

  	corporateRemoveCandidate(param): Observable<any> {
		return this.http.post(this.CORPORATEREMOVECANDIDATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>(this.CORPORATEREMOVECANDIDATE_URL))
  		);
	}

  	corporateGetTags() {
  		return this.http.get(this.CORPORATEGETTAGS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>(this.CORPORATEGETTAGS_URL))
	  	);
  	}

  	corporateGetCandidatesByTag(id) {
  		return this.http.get(this.CORPORATESAVEDCANDIDATES_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>(this.CORPORATESAVEDCANDIDATES_URL))
	  	);
  	}

  	corporateAddTag(param): Observable<any> {
		return this.http.post(this.CORPORATEADDTAG_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>(this.CORPORATEADDTAG_URL))
  		);
	}

  	corporateSaveCandidate(param): Observable<any> {
		return this.http.post(this.CORPORATESAVECANDIDATE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>(this.CORPORATEADDTAG_URL))
  		);
	}

	corporateGetCandidateResume(id: any) {

		return this.http.get(this.CORPORATEGETCANDIDATERESUME_URL + id, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

  	corporateGetCandidateProfile(id: any): Observable<any> {
		return this.http.get(this.CORPORATEGETCANDIDATEPROFILE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}









	apply(param: any): Observable<any> {
	  return this.http.post(this.POSITIONAPPLY_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('application'))
	  	);
	}

	bookmark(param: any): Observable<any> {
	  return this.http.post(this.POSITIONSAVE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('bookmark'))
	  	);
	}



















	bookmarks(): Observable<any> {
	  return this.http.get(this.BOOKMARKS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('bookmarks'))
	  	);
	}

	getBookmark(id: number): Observable<any> {
	  return this.http.get(this.GETBOOKMARK_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	deleteBookmark(id: number): Observable<any> {
	  return this.http.delete(this.DELBOOKMARK_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('deleteBookmark'))
	  	);
	}





	getApplication(id: number): Observable<any> {
	  return this.http.get(this.GETAPPLICATION_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	postJob(param: any): Observable<any> {
	  return this.http.post(this.POSTJOB_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewJob'))
  		);
	}

	getOptions(): Observable<any> {
	  return this.http.get(this.GETOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	postCompany(param: any): Observable<any> {
	  return this.http.post(this.CREATECOMPANY_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	getCompany(): Observable<any> {
	  return this.http.get(this.COMPANYPROFILE_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getCompanyProfile'))
	  	);
	}

	updateCompany(param: any): Observable<any> {
	  return this.http.post(this.UPDATECOMPANY_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('updateCompanyProfile'))
	  	);
	}

	getCompanyPosts(): Observable<any> {
	  return this.http.get(this.COMPANYPOSTS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getCompanyPosts'))
	  	);
	}

	// POSITIONS
	getPositions(page: any): Observable<any> {
		
		var POSITIONS_URL = this.POSITIONS_URL + '?page=' + page;

		if (this.getToken() !== null) {
			return this.http.get(POSITIONS_URL, { headers: this.headers() })
			  	.pipe(
			    	catchError(this.handleError<JSON>('profile'))
			  	);
		} else {
			return  this.http.get(POSITIONS_URL);
		}
	}

	getPosition(id: number): Observable<any> {

		return this.http.get(this.POSITION_URL + id);
	}

	sharePosition(param, id): Observable<any> {
	  return this.http.post(this.POSITIONSHARE_URL + id, param)
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}


	// ADMIN
	getCorporateRequests(): Observable<any> {
		return this.http.get(this.ADMINCORPORATEREQUESTS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}



	getReportOptions(): Observable<any> {
		return this.http.get(this.ADMINUSERSREPORTOPTIONS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	verifyCorporateRequest(param: any, id): Observable<any> {
		return this.http.post(this.ADMINCORPORATEVERIFY_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	adminGetCRM(type): Observable<any> {
		return this.http.get(this.ADMINGETCRM_URL + type, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>(this.ADMINGETCRM_URL))
	  	);
	}

	adminAddCRM(param): Observable<any> {
		return this.http.post(this.ADMINADDCRM_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>(this.ADMINGETCRM_URL))
	  	);
	}

	adminUpdateCRM(id, param): Observable<any> {
		return this.http.patch(this.ADMINUPDATECRM_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>(this.ADMINGETCRM_URL))
	  	);
	}

	adminDeleteCRM(id, type): Observable<any> {
		return this.http.delete(this.ADMINDELETECRM_URL + type + '/' + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>(this.ADMINGETCRM_URL))
	  	);
	}


	adminCorporateGetSSM(id) {

		return this.http.get(this.ADMINCORPORATESSM_URL + id, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

  	adminCorporateGetForm9(id) {

		return this.http.get(this.ADMINCORPORATEFORM9_URL + id, { headers: this.fileHeaders(), responseType: 'blob' });
  	}

	searchSystemUser(param: any): Observable<any> {
		return this.http.post(this.ADMINUSERSEARCH_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	searchCorporate(param: any): Observable<any> {
		return this.http.post(this.ADMINCORPORATESEARCH_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>(this.ADMINCORPORATESEARCH_URL))
  		);
	}

	getAdminUserProfile(id: any): Observable<any> {
		return this.http.get(this.ADMINUSERPROFILE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('getAdminUserProfile'))
  		);
	}

	getAdminCorporateProfile(id: any): Observable<any> {
		return this.http.get(this.ADMINCORPORATEPROFILE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('getAdminCorporateProfile'))
  		);
	}

	getAdminCorporateSubsidiaryProfile(id: any): Observable<any> {
		return this.http.get(this.ADMINCORPORATESUBSIDIARYPROFILE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('getAdminCorporateSubsidiaryProfile'))
  		);
	}

	corporateCollaborators(): Observable<any> {
	  return this.http.get(this.CORPORATECOLLABORATORS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	corporateAddCollaborator(param): Observable<any> {
	  return this.http.post(this.CORPORATEADDCOLLABORATOR_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	corporateRemoveCollaborator(id): Observable<any> {
	 return this.http.delete(this.CORPORATEREMOVECOLLABORATOR_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
  	}

  	corporateToggleCollaboratorActivate(id): Observable<any> {
	  return this.http.post(this.CORPORATETOGGLECOLLABORATOR_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}


	banSystemUser(id: any): Observable<any> {
		return this.http.post(this.ADMINUSERBAN_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	banCorporate(type, id: any): Observable<any> {
		return this.http.post(this.ADMINCORPORATEBAN_URL + id + '?type=' + type, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	unbanSystemUser(id: any): Observable<any> {
		return this.http.post(this.ADMINUSERUNBAN_URL + id, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	unbanCorporate(type, id: any): Observable<any> {
		return this.http.post(this.ADMINCORPORATEUNBAN_URL + id + '?type=' + type, null, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	userChangePassword(param: any): Observable<any> {
		return this.http.post(this.USERCHANGEPASSWORD_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	adminUsersReport(param: any): Observable<any> {
		return this.http.post(this.ADMINUSERSREPORT_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	adminSystemIssues(): Observable<any> {
		return this.http.get(this.ADMINSYSTEMISSUES_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	adminSystemIssueResolve(id: number): Observable<any> {
		return this.http.get(this.ADMINSYSTEMISSUERESOLVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	adminSystemEnquiries(): Observable<any> {
		return this.http.get(this.ADMINSYSTEMENQUIRIES_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	adminSystemEnquiryResolve(id: number): Observable<any> {
		return this.http.get(this.ADMINSYSTEMENQUIRYRESOLVE_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	systemReportIssue(param: any): Observable<any> {
		return this.http.post(this.SYSTEMREPORTISSUE_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<any>('postNewCompany'))
  		);
	}

	adminUsers(): Observable<any> {
	  return this.http.get(this.ADMINUSERS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	adminAddUser(param): Observable<any> {
	  return this.http.post(this.ADMINUADDUSER_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	adminRemoveUser(id): Observable<any> {
	 return this.http.delete(this.ADMINUREMOVEUSER_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
  	}

	searchOptions(): Observable<any> {
	  return this.http.get(this.SEARCHJOBOPTIONS_URL)
	  	.pipe(
	    	catchError(this.handleError<JSON>('profile'))
	  	);
	}

	searchJob(param: any, page:any): Observable<any> {
  		if (this.getToken() !== null) {
			return this.http.get(this.SEARCHJOB_URL + '?page=' + page, { params: param, headers: this.headers() })
			  	.pipe(
			    	catchError(this.handleError<any>('postNewCompany'))
		  		);
		} else {
			return this.http.get(this.SEARCHJOB_URL + '?page=' + page, { params: param })
			  	.pipe(
			    	catchError(this.handleError<any>('postNewCompany'))
		  		);
		}
	}

	getMessageThreads(): Observable<any> {
		return this.http.get(this.MESSAGETHREADS_URL, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	getMessages(id: any): Observable<any> {
		return this.http.get(this.MESSAGES_URL + id, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	postMessage(param: any, id): Observable<any> {
		return this.http.post(this.POSTMESSAGE_URL + id, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}

	createThread(param: any): Observable<any> {
		return this.http.post(this.CREATETHREAD_URL, param, { headers: this.headers() })
	  	.pipe(
	    	catchError(this.handleError<JSON>('getBookmark'))
	  	);
	}



	private handleError<JSON> (operation = 'operation', result?: JSON) {
	  return (error: any): Observable<JSON> => {

	    result = error.error;
	    console.log(result);
	    return of(result as JSON);
  	};
}


}
