import { Component, OnInit, Input } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

	private agree = false;
	form: FormGroup;
    submitted = false;

    declarativeFormCaptchaValue: any;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder

	) { }

	ngOnInit() {
		 this.form = this.fb.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            cPassword: ['', [Validators.required, Validators.minLength(6)]]
        });
	}

	resolved(captchaResponse: string) {
		// console.log(`Resolved captcha with response ${captchaResponse}:`);
	}

	register(form: NgForm): void {

		if (!form.valid) {
			alert('Please make sure you are not a robot.');
		} else {
			this.submitted = true;

			if (this.form.valid) {
				if (this.passwordMatch()) {
					if (this.agree) {
						this.apiService.register(this.form.value).subscribe(data => {
						if (data.status) {
								alert(data.message);
								this.router.navigate(['/login']);
							} else {
								alert(data.message);
							}
						});
					} else {
						alert('Kindly agree on the T&C and Privacy Policy in order to register with MAAx.jobs');
					}
				} else {
					alert('Password did not match, try again.');
				}
			}
		}


	}

	get f() { return this.form.controls; }


	toggletnc(event) {
	    if ( event.target.checked ) {
	         this.agree = true;
	    } else {
	    	this.agree = false;
					}
	}

	passwordMatch(): boolean {

		if (this.form.controls['password'].value === this.form.controls['cPassword'].value) {
			return true;
		}

		return false;
	}
}
