import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-education',
	templateUrl: './education.component.html',
	styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {

	user: any;
	company: any;
	token: string;
	education: any;
	educations: any;
	options: any;
	new = true;

	form: FormGroup;
    submitted = false;

    otherEducationField = false;
    otherUniversity = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.index();

		this.form = this.fb.group({
            qualification_id: ['', [Validators.required]],
            start_date: ['', [Validators.required]],
            end_date: ['', [Validators.required]],
            location: ['', [Validators.required]],
            grade: ['', [Validators.required]],
            description: ['', [Validators.required]],
            education_field_id: ['', [Validators.required]],
            university_id: ['', [Validators.required]],
            education_field: ['', []],
            university: ['', []]
        });
	}

	get f() { return this.form.controls; }


	index(): void {

		this.apiService.personalEducationOptions().subscribe(data => {

			this.options = data.data;
			this.getEducations();
		});
	}

	getEducations(): void {

		this.apiService.personalEducations().subscribe(data => {
			this.educations = data.data;
		});
	}

	create(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalCreateEducation(this.form.value).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.submitted = false;
				}

				this.index();
				alert(data.message);
			});
		}
	}

	edit(education: any): void {

		window.scroll(0, 0);
		this.new = false;
		this.education = education;

		this.form.setValue({
			education_field_id: education.education_field_id,
			university_id: education.university_id,
			qualification_id: education.qualification_id,
			start_date: education.start_date,
			end_date: education.end_date,
			location: education.location,
			grade: education.grade,
			description: education.description,
			education_field: '',
        	university: ''
		});
	}

	update(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalUpdateEducation(this.form.value, this.education.id).subscribe(data => {

				if (data.status) {
					this.form.reset();
				}

				this.setNew();
				this.index();
			});
		}
	}

	delete(education: any): void {

		this.apiService.personalDeleteEducation(education.id).subscribe(data => {

			this.setNew();
			this.getEducations();
		});
	}

	setNew(): void {

		this.new = true;
		this.form.reset();
		this.submitted = false;

		this.closeOthers('education_field');
		this.closeOthers('university');
	}

	other(value, field): void {
		if (field == 'education_field' && value == 70) { // 7 == Others
			this.form.get('education_field_id').clearValidators();
			this.form.controls['education_field_id'].setValue('');
			this.form.get('education_field').setValidators([Validators.required]);
			this.otherEducationField = true;
		} else if (field == 'university' && value == 155) { // 7 == Others
			this.form.get('university_id').clearValidators();
			this.form.controls['university_id'].setValue('');
			this.form.get('university').setValidators([Validators.required]);
			this.otherUniversity = true;
		}
	}

	closeOthers(field): void {
		if (field == 'education_field') {
			this.form.get('education_field').clearValidators();
			this.form.controls['education_field'].setValue('');
			this.form.get('education_field_id').setValidators([Validators.required]);
			this.otherEducationField = false;
		} else if (field == 'university') {
			this.form.get('university').clearValidators();
			this.form.controls['university'].setValue('');
			this.form.get('university_id').setValidators([Validators.required]);
			this.otherUniversity = false;
		}
	}

}
