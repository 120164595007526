import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-about-company',
  templateUrl: './about-company.component.html',
  styleUrls: ['./about-company.component.css']
})
export class AboutCompanyComponent implements OnInit {

  	user: any;
	company: any;
	token: string;
	options: any;
	cities: any;

	image: any;

	form: FormGroup;
	submitted = false;

	otherIndustry = false;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.index();

		this.form = this.fb.group({
            company_name: ['', [Validators.required]],
            about: ['', [Validators.required]],
            since: ['', [Validators.required]],
            ssm: ['', [Validators.required]],
            industry_id: ['', [Validators.required]],
            employees: ['', [Validators.required]],
            address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            country_id: ['', [Validators.required]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: ['', [Validators.required, Validators.email]],
            latitude: ['', []],
            longitude: ['', []],
			facebook_link: ['', []],
			twitter_link: ['', []],
			google_link: ['', []],
			linkedin_link: ['', []],
			website: ['', []],
			industry: ['', []]

			// latitude: ['', [Validators.pattern('^[0-9]+(.[0-9]*$)?$')]],

        });
	}

	get f() { return this.form.controls; }


	index(): void {

		this.apiService.corporateAboutCompanyOptions().subscribe(data => {

			this.options 		= data.data;

			this.aboutCompanyImage();
			this.aboutCompany();
		});
	}

	onChange(value): void {
		if (value == '') {
			this.cities = [];
			this.form.controls['city'].setValue('');
		} else {
			this.cities = [];
			this.form.controls['city'].setValue('');
	    	this.getCitiesOfState(value);
		}
	 }

	getCitiesOfState(stateId): void {

		this.cities = (this.options.states.filter(state => state.name == stateId))[0].cities;
	}

	aboutCompany(): void {

		this.apiService.corporateAboutCompany().subscribe(data => {

			this.company 		= data.data;

			this.getCitiesOfState(this.company.state);

			this.form.setValue({

				company_name: this.company.company_name,
				about: this.company.about,
				since : this.company.since,
				ssm : this.company.ssm,
				industry_id : this.company.industry_id,
				employees : this.company.employees,
				address_1 : this.company.address_1,
				address_2 : this.company.address_2,
				city : this.company.city,
				state : this.company.state,
				zipcode : this.company.zipcode,
				country_id : this.company.country_id,
				latitude : this.company.latitude,
				longitude : this.company.longitude,
				contact_number : this.company.contact_number,
				contact_email : this.company.contact_email,
				facebook_link : this.company.facebook_link,
				twitter_link : this.company.twitter_link,
				google_link : this.company.google_link,
				linkedin_link : this.company.linkedin_link,
				website : this.company.website,
				industry: ''

			});
		});
	}

	updateAboutCompany(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.corporateUpdateAboutCompany(this.form.value).subscribe(data => {

				this.company 		= data.data;

				this.submitted = false;
				window.scroll(0, 0);
				alert(data.message);
				this.router.navigate(['/corporate/profile']);
				// this.getUser();
			});
		}
	}

	uploadFile(event): void {
	    const elem = event.target;
	    if (elem.files.length > 0) {
	      this.image = elem.files[0];

	      const formData: FormData = new FormData();
    	  formData.append('file', elem.files[0], elem.files[0].name);

	      this.apiService.corporateImage(formData).subscribe(data => {

				if (!data['status']) {
					alert(data['message']);
				}
			});
	    }
	}

	aboutCompanyImage(): void {

		this.apiService.corporateGetImage().subscribe(data => {

				this.image = data;
			});
	}

	other(value, field): void {
		if (field == 'industry' && value == 58) { // 7 == Others
			this.form.get('industry_id').clearValidators();
			this.form.controls['industry_id'].setValue('');
			this.form.get('industry').setValidators([Validators.required]);
			this.otherIndustry = true;
		}
	}

	closeOthers(field): void {
		if (field == 'industry') {
			this.form.get('industry').clearValidators();
			this.form.controls['industry'].setValue('');
			this.form.get('industry_id').setValidators([Validators.required]);
			this.otherIndustry = false;
		}
	}
}
