import { NgModule }             		from '@angular/core';
import { RouterModule, Routes } 		from '@angular/router';
import { AuthGuard } 					from './guards/auth.guard';
import { AdminGuardGuard } 				from './guards/admin-guard.guard';


import { IndexComponent } 				from './index/index.component';
import { PositionsComponent } 			from './positions/positions.component';
import { SignupComponent } 				from './signup/signup.component';
import { SigninComponent } 				from './signin/signin.component';
import { ResetPasswordComponent } 		from './reset-password/reset-password.component';
import { PositionDetailComponent } 		from './position-detail/position-detail.component';
import { MyBookmarkDetailComponent } 	from './my-bookmark-detail/my-bookmark-detail.component';
import { MyApplicationDetailComponent } from './my-application-detail/my-application-detail.component';
import { PostsComponent } 				from './posts/posts.component';
import { CompanyPostsComponent } 		from './company-posts/company-posts.component';
import { PostNewComponent } 			from './post-new/post-new.component';
import { CompanyNewComponent } 			from './company-new/company-new.component';
import { CompanyProfileComponent } 		from './company-profile/company-profile.component';

// SYSTEM ROUTES
import { TermsOfUseComponent } 			from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } 		from './privacy-policy/privacy-policy.component';
import { HowItWorksComponent } 			from './how-it-works/how-it-works.component';
import { ForEmployersComponent } 		from './for-employers/for-employers.component';
import { FaqComponent } 				from './faq/faq.component';

// PERSONAL ROUTES
import { ProfileComponent } 			from './profile/profile.component';
import { AboutMeComponent } 			from './about-me/about-me.component';
import { ExperienceComponent } 			from './experience/experience.component';
import { EducationComponent } 			from './education/education.component';
import { SkillComponent } 				from './skill/skill.component';
import { LanguageComponent } 			from './language/language.component';
import { DocUploadComponent } 			from './doc-upload/doc-upload.component';
import { JobsIWantComponent } 			from './jobs-i-want/jobs-i-want.component';
import { JobsIWantEditComponent } 		from './jobs-i-want-edit/jobs-i-want-edit.component';
import { MyApplicationsComponent } 		from './my-applications/my-applications.component';
import { MyBookmarksComponent } 		from './my-bookmarks/my-bookmarks.component';
import { IAmHiringComponent } 			from './i-am-hiring/i-am-hiring.component';
import { IAmHiringEditComponent } 		from './i-am-hiring-edit/i-am-hiring-edit.component';
import { MyPostedJobsComponent } 		from './my-posted-jobs/my-posted-jobs.component';
import { PostedJobDetailComponent } 	from './posted-job-detail/posted-job-detail.component';
import { PrivacySettingsComponent } 	from './privacy-settings/privacy-settings.component';
import { AccountSettingsComponent } 	from './account-settings/account-settings.component';
import { ReportIssueComponent } 		from './report-issue/report-issue.component';

// CORPORATE ROUTES
import { CorporateVerificationComponent }from './corporate-verification/corporate-verification.component';
import { AboutCompanyComponent } 		from './about-company/about-company.component';
import { CompanySubsidiariesComponent } from './company-subsidiaries/company-subsidiaries.component';
import { CompanySubsidiariesEditComponent } from './company-subsidiaries-edit/company-subsidiaries-edit.component';
import { CorporateCollaboratorsComponent } from './corporate-collaborators/corporate-collaborators.component';
import { CreateJobsComponent } 			from './create-jobs/create-jobs.component';
import { CompanyPostedJobsComponent } 	from './company-posted-jobs/company-posted-jobs.component';
import { CompanyPostedJobComponent } 	from './company-posted-job/company-posted-job.component';
import { CompanyEditJobComponent } 		from './company-edit-job/company-edit-job.component';
import { HistoryComponent } 			from './history/history.component';
import { CorporateCollaboratedComponent } 			from './corporate-collaborated/corporate-collaborated.component';
import { SearchCandidatesComponent } 	from './search-candidates/search-candidates.component';
import { SearchCandidatesProfileComponent } from './search-candidates-profile/search-candidates-profile.component';
import { SavedCandidatesComponent } 	from './saved-candidates/saved-candidates.component';

import { InboxMessagingComponent } 			from './inbox-messaging/inbox-messaging.component';

import { UserActivationComponent } 			from './user-activation/user-activation.component';
import { ResetPasswordCompleteComponent } 	from './reset-password-complete/reset-password-complete.component';


// ADMIN
import { MaaAdminComponent } 					from './maa-admin/maa-admin.component';
import { MaaAdminUserProfileComponent } 		from './maa-admin-user-profile/maa-admin-user-profile.component';
import { MaaAdminCorporateProfileComponent } 	from './maa-admin-corporate-profile/maa-admin-corporate-profile.component';
import { MaaAdminCorporateSubsidiaryProfileComponent } 	from './maa-admin-corporate-subsidiary-profile/maa-admin-corporate-subsidiary-profile.component';

const routes: Routes = [
	{ path: '', 						component: IndexComponent },
	{ path: 'index', 					component: IndexComponent },
	{ path: 'register', 				component: SignupComponent },
	{ path: 'login', 					component: SigninComponent },
	{ path: 'reset-password', 			component: ResetPasswordComponent },
	{ path: 'positions', 				component: PositionsComponent },
	{ path: 'positions?page=:page', 	component: PositionsComponent },
	{ path: 'positions/:title/:advanced',component: PositionsComponent },
	{ path: 'positions/:id', 			component: PositionDetailComponent },
	// { path: 'profile', 					component: ProfileComponent },
	// { path: 'posts/create', 			component: PostNewComponent, 				canActivate: [AuthGuard] },
	// { path: 'posts', 					component: PostsComponent },
	// { path: 'bookmarks/:id', 			component: MyBookmarkDetailComponent },
	// { path: 'applications/:id', 		component: MyApplicationDetailComponent },
	{ path: 'company/create', 			component: CompanyNewComponent },
	// { path: 'company/posts', 			component: CompanyPostsComponent },

	{ path: 'terms-of-use', 			component: TermsOfUseComponent },
	{ path: 'privacy-policy', 			component: PrivacyPolicyComponent },
	{ path: 'how-to-use',				component: HowItWorksComponent },
	{ path: 'for-employers', 			component: ForEmployersComponent },
	{ path: 'faq', 						component: FaqComponent },

	{ path: 'account-settings', 		component: AccountSettingsComponent, 				canActivate: [AuthGuard] },
	{ path: 'report-issue', 			component: ReportIssueComponent, 				canActivate: [AuthGuard] },

	{ path: 'personal/profile', 		component: ProfileComponent, 				canActivate: [AuthGuard] },
	{ path: 'personal/about-me', 		component: AboutMeComponent, 				canActivate: [AuthGuard] },
	{ path: 'personal/experience', 		component: ExperienceComponent, 			canActivate: [AuthGuard] },
	{ path: 'personal/education', 		component: EducationComponent, 				canActivate: [AuthGuard] },
	{ path: 'personal/skills', 			component: SkillComponent, 					canActivate: [AuthGuard] },
	{ path: 'personal/language', 		component: LanguageComponent, 				canActivate: [AuthGuard] },
	{ path: 'personal/resume', 			component: DocUploadComponent, 				canActivate: [AuthGuard] },
	{ path: 'personal/privacy', 		component: PrivacySettingsComponent, 		canActivate: [AuthGuard] },
	{ path: 'personal/applied-jobs', 	component: MyApplicationsComponent, 		canActivate: [AuthGuard] },
	{ path: 'personal/saved-jobs', 		component: MyBookmarksComponent, 					canActivate: [AuthGuard] },
	{ path: 'personal/jobs-i-want', 	component: JobsIWantComponent, 					canActivate: [AuthGuard] },
	{ path: 'personal/jobs-i-want/:id', component: JobsIWantEditComponent, 					canActivate: [AuthGuard] },
	{ path: 'personal/i-am-hiring', 	component: IAmHiringComponent, 					canActivate: [AuthGuard] },
	{ path: 'personal/i-am-hiring/:id',	component: IAmHiringEditComponent, 					canActivate: [AuthGuard] },
	{ path: 'personal/posted-jobs', 	component: MyPostedJobsComponent, 					canActivate: [AuthGuard] },
	{ path: 'personal/posted-jobs/:id', component: PostedJobDetailComponent, 					canActivate: [AuthGuard] },
	{ path: 'personal/posted-jobs/:id/:status', component: PostedJobDetailComponent, 					canActivate: [AuthGuard] },

	{ path: 'corporate/profile', 		component: CompanyProfileComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/about-company', 	component: AboutCompanyComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/verification', 	component: CorporateVerificationComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/subsidiaries', 	component: CompanySubsidiariesComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/subsidiaries/:id',component: CompanySubsidiariesEditComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/collaborators',	component: CorporateCollaboratorsComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/create-jobs', 	component: CreateJobsComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/jobs-posted', 	component: CompanyPostedJobsComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/posted-jobs/:id', component: CompanyPostedJobComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/posted-jobs/:id/:saved', component: CompanyPostedJobComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/posted-jobs/:id/:status', component: CompanyPostedJobComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/edit-job/:id',	component: CompanyEditJobComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/history', 		component: HistoryComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/collaborated', 		component: CorporateCollaboratedComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/search-candidates',		component: SearchCandidatesComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/search-candidates/:id',		component: SearchCandidatesProfileComponent, 				canActivate: [AuthGuard] },
	{ path: 'corporate/saved-candidates',		component: SavedCandidatesComponent, 				canActivate: [AuthGuard] },

	{ path: 'maa-admin', 						component: MaaAdminComponent, 				canActivate: [AuthGuard, AdminGuardGuard] },
	{ path: 'maa-admin/user-profile/:id', 		component: MaaAdminUserProfileComponent, 				canActivate: [AuthGuard, AdminGuardGuard] },
	{ path: 'maa-admin/corporate-profile/:id', 	component: MaaAdminCorporateProfileComponent, 				canActivate: [AuthGuard, AdminGuardGuard] },
	{ path: 'maa-admin/corporate-profile/subsidiary/:id', 	component: MaaAdminCorporateSubsidiaryProfileComponent, 				canActivate: [AuthGuard, AdminGuardGuard] },

	{ path: 'inbox-messaging', 					component: InboxMessagingComponent, 				canActivate: [AuthGuard] },

	{ path: 'activate', 						component: UserActivationComponent },
	{ path: 'complete-reset-password', 			component: ResetPasswordCompleteComponent },
];


@NgModule({
	imports: [ RouterModule.forRoot(routes, {
		// scrollPositionRestoration: 'enabled',
		anchorScrolling: 'enabled',
		// anchorScrolling: 'enabled',
		// onSameUrlNavigation: 'reload',
		// enableTracing: true
	}), ],
	exports: [ RouterModule ]

})
export class AppRoutingModule {}
