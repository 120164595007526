import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-corporate-verification',
  templateUrl: './corporate-verification.component.html',
  styleUrls: ['./corporate-verification.component.css']
})
export class CorporateVerificationComponent implements OnInit {

	form44: any;
	form9: any;

	maxFileForm44 = false;
	maxFileForm9 = false;

	data: any;

  	constructor(
  		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
	) { }

  	ngOnInit() {
  		this.index();
  	}

  	index(): void {

		this.apiService.corporateDocuments().subscribe(data => {

			this.data = data['data'];
			this.form44 = this.data['form44'];
			this.form9 = this.data['form9'];
		});
	}


  	uploadForm44(event): void {
  		if (this.data['profile_completed'] == true) {
			const elem = event.target;
		    if (elem.files.length > 0) {

			    if (elem.files[0].size > 1000024) {

			    	this.maxFileForm44 = true;
			    	alert('Please upload a PDF file lower than 1MB of size.');
			    } else {
			    	this.maxFileForm44 = false;
					this.form44 = elem.files[0];

					const formData: FormData = new FormData();
					formData.append('file', elem.files[0], elem.files[0].name);

					this.apiService.corporateUploadSSM(formData).subscribe(data => {

						// if(!data['status'])
						alert(data['message']);
						this.index();

					});
			    }
			}
  		} else {
  			alert('Please fill up corporate profile details first.');
  		}

	}

	getForm44(): void {

		this.apiService.corporateGetSSM().subscribe(data => {

				const blob = new Blob([data], {type: 'application/pdf'});
				if (blob.size > 2) {
					const file = window.URL.createObjectURL(blob);
					saveAs(file, 'form44.pdf');
				} else {
					alert('No Form 44 document uploaded previously');
				}
			});
	}

	uploadForm9(event): void {
		if (this.data['profile_completed'] == true) {
		    const elem = event.target;
		    if (elem.files.length > 0) {

		    	if (elem.files[0].size > 1000024) {

		    		this.maxFileForm9 = true;
			    	alert('Please upload a PDF file lower than 1MB of size.');
			    } else {
			    	this.maxFileForm9 = false;
			    	this.form9 = elem.files[0];

					const formData: FormData = new FormData();
					formData.append('file', elem.files[0], elem.files[0].name);

					this.apiService.corporateUploadForm9(formData).subscribe(data => {

					// if(!data['status'])
						alert(data['message']);
						this.index();
					});
			    }

		    }
	    } else {
  			alert('Please fill up corporate profile details first.');
  		}
	}

	getForm9(): void {

		this.apiService.corporateGetForm9().subscribe(data => {

				const blob = new Blob([data], {type: 'application/pdf'});
				if (blob.size > 2) {
					const file = window.URL.createObjectURL(blob);
					saveAs(file, 'form9.pdf');
				} else {
					alert('No Form 9 document uploaded previously');
				}
			});
	}

}
