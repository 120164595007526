import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-jobs-i-want',
  templateUrl: './jobs-i-want.component.html',
  styleUrls: ['./jobs-i-want.component.css']
})
export class JobsIWantComponent implements OnInit {

  	user: any;
	company: any;
	token: string;
	lang: any;
	// langs: any;
	options: any;
	objects: any;
	new = false;
	// langs: any;

	form: FormGroup;
    submitted = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
		) { }

	ngOnInit() {
		this.get();

		this.form = this.fb.group({
            keyword: ['', [Validators.required]],
            expected_salary: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            location: ['', [Validators.required]],
            specialization_id: ['', [Validators.required]],
            position_level_id: ['', [Validators.required]],
            education_field_id: ['', [Validators.required]],
            job_type: ['', [Validators.required]],
            // years_range_id: ['', [Validators.required]],
            experience_years: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            email_frequency: ['', [Validators.required]]

        });
	}

	get f() { return this.form.controls; }

	index(): void {

		window.scroll(0, 0);

		this.apiService.personalSeekingOptions().subscribe(data => {

			this.options = data.data;
			// this.langs = data.data.languages;
			// this.getAll();
		});
	}

	create(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalCreateSeeking(this.form.value).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.toggle();
				}

				alert(data.message);
				this.router.navigate(['/personal/jobs-i-want']);


			});
		}
	}

	get(): void {

		this.apiService.personalSeekings().subscribe(data => {

			this.objects = data.data;
		});
	}

	patch(object: any): void {

		this.router.navigate(['/personal/jobs-i-want/' + object.id]);
	}

	activate(object: any): void {
		this.apiService.personalActivateSeeking(object.id).subscribe(data => {

			if (data.status) {
				this.get();
			} else {
				alert(data.message);
			}
		});
	}

	deactivate(object: any): void {

		this.apiService.personalDeactivateSeeking(object.id).subscribe(data => {

			if (data.status) {
				this.get();
			} else {
				alert(data.message);
			}
		});
	}

	delete(object: any): void {

		this.apiService.personalDeleteSeeking(object.id).subscribe(data => {

			if (data.status) {
				this.get();
			} else {
				alert(data.message);
			}
		});
	}



	toggle(): void {

		if (this.new === false) {
			this.index();
			this.new = true;
		} else {
			this.new = false;
			this.get();
			this.submitted = false;
		}
	}

}
