import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-subsidiaries',
  templateUrl: './company-subsidiaries.component.html',
  styleUrls: ['./company-subsidiaries.component.css']
})
export class CompanySubsidiariesComponent implements OnInit {

  	user: any;
	
	company: any;
	subsidiaries: any;


	token: string;
	options: any;
	cities: any;

	image: any;

	form: FormGroup;
	submitted = false;

	otherIndustry = false;

	new = true;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.index();

		this.form = this.fb.group({
            avatar: ['', [Validators.required]],
            company_name: ['', [Validators.required]],
            about: ['', [Validators.required]],
            since: ['', [Validators.required]],
            ssm: ['', [Validators.required]],
            industry_id: ['', [Validators.required]],
            employees: ['', [Validators.required]],
            address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            country_id: ['', [Validators.required]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: ['', [Validators.required, Validators.email]],
            latitude: ['', []],
            longitude: ['', []],
			facebook_link: ['', []],
			twitter_link: ['', []],
			google_link: ['', []],
			linkedin_link: ['', []],
			website: ['', []],
			industry: ['', []]

			// latitude: ['', [Validators.pattern('^[0-9]+(.[0-9]*$)?$')]],

        });
	}

	get f() { return this.form.controls; }


	index(): void {

		this.apiService.corporateAboutCompanyOptions().subscribe(data => {

			this.options 		= data.data;
			this.aboutCompany();
		});
	}

	onChange(value): void {
		if (value == '') {
			this.cities = [];
			this.form.controls['city'].setValue('');
		} else {
			this.cities = [];
			this.form.controls['city'].setValue('');
	    	this.getCitiesOfState(value);
		}
	 }

	getCitiesOfState(stateId): void {

		this.cities = (this.options.states.filter(state => state.name == stateId))[0].cities;
	}

	aboutCompany(): void {

		this.apiService.corporateAboutCompanySubsidiaries().subscribe(data => {

			this.company 		= data.data;
			this.subsidiaries	= this.company.subsidiaries;
			// this.getCitiesOfState(this.company.state);
		});
	}

	sameAddress(event) {
		if ( event.target.checked ) {
			this.form.controls['address_1'].setValue(this.company.address_1);
			this.form.controls['address_2'].setValue(this.company.address_2);
			this.form.controls['city'].setValue(this.company.city);
			this.form.controls['state'].setValue(this.company.state);
			this.form.controls['zipcode'].setValue(this.company.zipcode);
			this.form.controls['country_id'].setValue(this.company.country_id);
			this.form.controls['latitude'].setValue(this.company.latitude);
			this.form.controls['longitude'].setValue(this.company.longitude);
			this.getCitiesOfState(this.company.state);
		}
		else {
			this.form.controls['address_1'].setValue('');
			this.form.controls['address_2'].setValue('');
			this.form.controls['city'].setValue('');
			this.form.controls['state'].setValue('');
			this.form.controls['zipcode'].setValue('');
			this.form.controls['country_id'].setValue('');
			this.form.controls['latitude'].setValue('');
			this.form.controls['longitude'].setValue('');
			this.getCitiesOfState('');
		}

	}

	sameContact(event) {
		if ( event.target.checked ) {
			this.form.controls['contact_number'].setValue(this.company.contact_number);
	    	this.form.controls['contact_email'].setValue(this.company.contact_email);
		}
		else {
			this.form.controls['contact_number'].setValue('');
	    	this.form.controls['contact_email'].setValue('');
		}
	}

	sameMedia(event) {
	    if ( event.target.checked ) {
	    	this.form.controls['facebook_link'].setValue(this.company.facebook_link);
	    	this.form.controls['twitter_link'].setValue(this.company.twitter_link);
	    	this.form.controls['google_link'].setValue(this.company.google_link);
	    	this.form.controls['linkedin_link'].setValue(this.company.linkedin_link);
	    	this.form.controls['website'].setValue(this.company.website);
	    } 
	    else {
	    	this.form.controls['facebook_link'].setValue('');
	    	this.form.controls['twitter_link'].setValue('');
	    	this.form.controls['google_link'].setValue('');
	    	this.form.controls['linkedin_link'].setValue('');
	    	this.form.controls['website'].setValue('');
	    	
	    }
	}

	create(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.corporateCreateCompanySubsidiary(this.form.value).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.submitted = false;
				} else {
					alert(data.message);
				}

				this.setNew();
				this.aboutCompany();
			});
		}
	}

	setNew(): void {

		this.new = true;
		this.form.reset();
		this.submitted = false;
		this.image = null;
	}

	uploadFile(event): void {
	    const elem = event.target;
	    if (elem.files.length > 0) {
	      this.image = elem.files[0];

	      const formData: FormData = new FormData();
    	  formData.append('file', elem.files[0], elem.files[0].name);

	      this.apiService.corporateSubsidiaryImage(formData).subscribe(data => {

				if (!data['status']) {
					alert(data['message']);
				}
				else {
					this.form.controls['avatar'].setValue(data['data']);
				}
			});
	    }
	}

	edit(subsidiary: any): void {

		window.scroll(0, 0);
		this.router.navigate(['/corporate/subsidiaries/' + subsidiary.id]);
	}

	delete(subsidiary: any): void {

		this.apiService.corporateDeleteSubsidiary(subsidiary.id).subscribe(data => {
			
			if(!data.status)
				alert(data.message);
			
			this.aboutCompany();
		});
	}

	aboutCompanyImage(): void {

		this.apiService.corporateGetImage().subscribe(data => {

				this.image = data;
			});
	}

	other(value, field): void {
		if (field == 'industry' && value == 58) { // 7 == Others
			this.form.get('industry_id').clearValidators();
			this.form.controls['industry_id'].setValue('');
			this.form.get('industry').setValidators([Validators.required]);
			this.otherIndustry = true;
		}
	}

	closeOthers(field): void {
		if (field == 'industry') {
			this.form.get('industry').clearValidators();
			this.form.controls['industry'].setValue('');
			this.form.get('industry_id').setValidators([Validators.required]);
			this.otherIndustry = false;
		}
	}
}
