import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router, NavigationExtras} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
import { saveAs } from 'file-saver';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-posted-job-detail',
  templateUrl: './posted-job-detail.component.html',
  styleUrls: ['./posted-job-detail.component.css']
})
export class PostedJobDetailComponent implements OnInit {

  	post: any;
  	selectedType: String;

  	userId: null;
  	jobId: any;

	advanced = false;
	submitted = false;

	filterData: any;
	races: any[] = [];
	nationalities: any[] = [];
	specializations: any[] = [];
	position_roles: any[] = [];
	industries: any[] = [];
	qualifications: any[] = [];
	study_fields: any[] = [];
	languages: any[] = [];
	skills: any[] = [];

	form: FormGroup;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
		) { }

	ngOnInit() {
		this.jobId = this.route.snapshot.paramMap.get('id');

		this.selectedType = 'pending';

		this.form = this.fb.group({
			gender: ['', ],
			min_age: ['', Validators.pattern('^[0-9]*$')],
			max_age: ['', Validators.pattern('^[0-9]*$')],
			min_salary: ['', Validators.pattern('^[0-9]*$')],
			max_salary: ['', Validators.pattern('^[0-9]*$')],
			min_years: ['', Validators.pattern('^[0-9]*$')],
			max_years: ['', Validators.pattern('^[0-9]*$')],
			last_updated: ['', ],
            races: [this.races, ],
            nationalities: [this.nationalities, ],
            specializations: [this.specializations, ],
            position_roles: [this.position_roles, ],
            industries: [this.industries, ],
            qualifications: [this.qualifications, ],
            study_fields: [this.study_fields, ],
            languages: [this.languages, ],
            skills: [this.skills, ],
            status: ['', ],
        });

		if (this.route.snapshot.params['status']) {
			this.selectedType = this.route.snapshot.params['status'];
		}

		this.index(this.selectedType);
	}

	get f() { return this.form.controls; }

	index(type: String): void {
		this.apiService.personalHiringByStatus(type, this.jobId).subscribe(data => {

			if (data.status) {
				this.post = data.data;
			} else {
				alert(data.message);
			}

			this.filterIndex();
		});
	}

	filterIndex() {
		this.apiService.personalHIringFilterOptions().subscribe(data => {

			this.filterData = data['data'];
		});

	}

	filterApplicants(id) {
		this.submitted = true;

		if (this.form.valid) {
			this.advanced = false;

			this.form.controls['status'].setValue(this.selectedType);

			this.apiService.personalFilterApplicants(this.form.value, id).subscribe(data => {

				if (data.status) {
					this.submitted = false;
					this.post  				= data.data;
				} else {
					alert(data.message);
				}
			});

			window.scroll(0, 0);
		}
	}

	clearFilter(): void {

		this.form.reset();
		this.races = [];
		this.nationalities = [];
		this.specializations = [];
		this.position_roles = [];
		this.industries = [];
		this.qualifications = [];
		this.study_fields = [];
		this.languages = [];
		this.skills = [];
	}

	onChangeAdd(item, type) {

		this[type].push(item);
    }

	onChangeRemove(item, type) {

		const index = this[type].findIndex(d => d.id === item.id); //find index in your array
        this[type].splice(index, 1); //remove element from array
    }

	selected(type: String): void {

		this.selectedType = type;
		this.index(type);
		this.form.controls['status'].setValue(type);
	}

	toggleSearch(): void {

		if (this.advanced) {
			this.advanced = false;
		} else {
			this.advanced = true;
		}
	}

	respond(status: String, post: any): void {

		const params = {};
		params['status'] = status;

		this.apiService.personalRespondApplication(params, post.id).subscribe(data => {

			if (data.status) {
				this.index(this.selectedType);
			} else {
				alert(data.message);
			}
		});
	}

	message(application: any): void {

		this.userId = application.user_id;

		const params = {};
		params['clientId'] = this.userId;

		this.apiService.createThread(params).subscribe(data => {

			if (data.status) {
				this.router.navigate(['/inbox-messaging']);
			} else {
				alert(data.message);
			}
		});
	}

	getResume(user: any): void {

		this.apiService.personalGetApplicantResume(user.id).subscribe(data => {

				const blob = new Blob([data], {type: 'application/pdf'});
				if (blob.size > 2) {
					const file = window.URL.createObjectURL(blob);
					saveAs(file, 'resume.pdf');
				} else {
					alert('No Resume document uploaded previously');
				}
			});
	}
}
