import { Component, OnInit } from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } 					from  '../api.service';

@Component({
  selector: 'app-corporate-collaborators',
  templateUrl: './corporate-collaborators.component.html',
  styleUrls: ['./corporate-collaborators.component.css']
})
export class CorporateCollaboratorsComponent implements OnInit {

	submitted = false;

	form: FormGroup;
	collaborators: any;

  constructor(
  		private apiService: ApiService,
        private fb: FormBuilder,
    ) { }

  get f() { return this.form.controls; }

  ngOnInit() {

  	this.index();

  	this.form = this.fb.group({
        email: ['',  [Validators.required, Validators.email]],
    });
  }

  index(): void {
        this.apiService.corporateCollaborators().subscribe(data => {

            if (data.status) {
                this.collaborators            = data.data;
            } else {
                alert(data.message);
            }
            window.scroll(0,0);
        });
    }

    addCollaborator(): void {
        this.submitted = true;
        if (this.form.valid) {
            this.apiService.corporateAddCollaborator(this.form.value).subscribe(data => {
                    alert(data.message);
                    this.submitted = false;
                    this.form.reset();
                    this.index();
            });
        }
    }

    removeCollaborator(id): void {
        this.apiService.corporateRemoveCollaborator(id).subscribe(data => {
            alert(data.message);
            this.index();
        });
    }

    toggleActivate(id): void {
        this.apiService.corporateToggleCollaboratorActivate(id).subscribe(data => {
            alert(data.message);
            this.index();
        });
    }
}
