import { Component, OnInit } 			from '@angular/core';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';

@Component({
	selector: 'app-post-new',
	templateUrl: './post-new.component.html',
	styleUrls: ['./post-new.component.css']
})
export class PostNewComponent implements OnInit {

	json: any;
	optionsJson: any;
	industries: any;
	types: any;
	categories: any;
	qualifications: any;
	experiences: any;
	career_levels: any;
	optionsArray: any[] = [];

	constructor(
		private apiService: ApiService,
		private router: Router
	) { }

	ngOnInit() {
		this.options();

	}

	add(form: NgForm): void {

		const params = form.value;
		// params['options'] = this.optionsArray;

		console.log(params);

		this.apiService.postJob(params).subscribe(data => {
			if (data.status) {
				alert(data.message);
				this.router.navigate(['/positions/' + data.data.id]);

			} else {
				alert(data.message);
			}
		});
	}

	options(): void {

		this.apiService.getOptions().subscribe(data => {

				if (data.status == false) {
					alert(data.message);
				} else {
					this.json               = data;
					this.optionsJson        = data.data;
					this.industries        	= data.data.industries;
					this.types        		= data.data.types;
					this.categories        	= data.data.categories;
					this.qualifications     = data.data.qualifications;
					this.experiences     	= data.data.experiences;
					this.career_levels     	= data.data.career_levels;
				}
		});
	}

	// addOption(form: NgForm): void {

	// 	this.optionsArray.push(form.value);
	// }
}
