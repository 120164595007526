import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-employers',
  templateUrl: './for-employers.component.html',
  styleUrls: ['./for-employers.component.css']
})
export class ForEmployersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
