import { Component, OnInit } from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

@Component({
  selector: 'app-my-application-detail',
  templateUrl: './my-application-detail.component.html',
  styleUrls: ['./my-application-detail.component.css']
})
export class MyApplicationDetailComponent implements OnInit {

	json;
	application;


  constructor(
  		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
	) { }

  ngOnInit() {
  	this.getApplication();
  }

  public getApplication(): void {
	  const id = +this.route.snapshot.paramMap.get('id');

	  this.apiService.getApplication(id).subscribe(data => {

			if (data.status == false) {
				alert(data.message);
				this.router.navigate(['/applications']);
			} else {
				this.json 						= data;
				this.application  				= data.data;
			}
		});
	}

	public delete(): void {

		const id = +this.route.snapshot.paramMap.get('id');

		// this.apiService.deleteApplication(id).subscribe(data => {

		// 	if(data.status == true)
		// 	{
		// 		alert(data.message);
		// 		this.router.navigate(['/applications']);
		// 	}
		// 	else {
		// 		alert(data.message);
		// 		this.router.navigate(['/applications']);
		// 	}
		// });
	}

}
