import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
import { saveAs } from 'file-saver';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.css']
})
export class DocUploadComponent implements OnInit {

  resume: any;
  video: any;

  maxFileResume = false;
  maxFileVideo = false;

  data: any;

  	constructor(
		private route: ActivatedRoute,
		  private apiService: ApiService,
		  private router: Router,
		  private location: Location
	) { }

	ngOnInit() {
	  this.index();
	}

	index(): void {

		this.apiService.personalDocuments().subscribe(data => {

			this.data = data['data'];
			this.resume = this.data['resume'];
			this.video = this.data['video'];
		});
	}

	uploadResume(event): void {
	  	const elem = event.target;
	  	if (elem.files.length > 0) {

			if (elem.files[0].size > 1000024) {

			  this.maxFileResume = true;
			  alert('Please upload a PDF file lower than 1MB of size.');
			} else {
			  	this.maxFileResume = false;
			  	this.resume = elem.files[0];

			  	const formData: FormData = new FormData();
			  	formData.append('file', elem.files[0], elem.files[0].name);

			  	this.apiService.personalUploadResume(formData).subscribe(data => {

					// if(!data['status'])
					alert(data['message']);
					this.index();

			  	});
			}
		}
	}

	getResume(): void {

		this.apiService.personalGetResume().subscribe(data => {

				const blob = new Blob([data], {type: 'application/pdf'});
				if (blob.size > 2) {
					const file = window.URL.createObjectURL(blob);
					saveAs(file, 'resume.pdf');
				} else {
					alert('No Resume document uploaded previously');
				}
			});
	}


	uploadVideo(event): void {
	  	const elem = event.target;
	  	if (elem.files.length > 0) {

			if (elem.files[0].size > 10000024) {

			  this.maxFileVideo = true;
			  alert('Please upload a video file lower than 10MB of size.');
			} else {
			  	this.maxFileVideo = false;
			  	this.video = elem.files[0];

			  	const formData: FormData = new FormData();
			  	formData.append('file', elem.files[0], elem.files[0].name);

			  	this.apiService.personalUploadVideo(formData).subscribe(data => {

					// if(!data['status'])
					alert(data['message']);
					this.index();

			  	});
			}
		}
	}

	getVideo(): void {

		this.apiService.personalGetVideo().subscribe(data => {

				const blob = new Blob([data], {type: 'video/mp4'});
				if (blob.size > 2) {
					const file = window.URL.createObjectURL(blob);
					saveAs(file, 'video.mp4');
				} else {
					alert('No video uploaded previously');
				}
			});
	}



}
