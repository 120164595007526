import { Component, OnInit, OnDestroy, AfterViewInit, OnChanges } 		from '@angular/core';
import { ApiService } 				from  '../api.service';
import { PagerService } 				from  '../pager.service';
import { ReuseService } 			from  '../reuse.service';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, Scroll } 	from '@angular/router';
import { Location } 				from '@angular/common';
import { filter } from 'rxjs/operators';

import {  ViewportScroller } from '@angular/common';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
	selector: 'app-positions',
	templateUrl: './positions.component.html',
	styleUrls: ['./positions.component.css'],
	// host: {'(window:scroll)': 'saveScrollPosition($event)'}
})
export class PositionsComponent implements OnInit, OnDestroy, AfterViewInit {

	advanced: any;

	simpleSearchValue;

	json;
	titleParam;
	category;
	page = 1;
	positions;
	positionsLength;

	options;

	optionsJson;
	optionsCategories;
	optionsIndustries;
	optionsExperiences;
	optionsQualifications;

	job_types: any[] = [];

	form: FormGroup;
	submitted = false;
	loggedIn = false;

	pageMaker: any = {};
	pagedItems: any[];

	constructor(
		private apiService:  ApiService,
		private dataService:  ReuseService,
		private route: ActivatedRoute,
    	private router: Router,
    	private location: Location,
		private fb: FormBuilder,
		private pager: PagerService
	) { 
	}

	get data():any { 
		return this.dataService.json; 
	} 
	set data(value: any) { 
		this.dataService.json = value; 
	}

	get scroll():any { 
		return this.dataService.scroll; 
	} 
	set scroll(value: any) { 
		this.dataService.scroll = value; 
	}



	ngOnInit() {

		window.scroll(0,0);
		
		this.initForm();

		this.checkParams();

		if(this.data !== null) {

			this.json 						= this.data;
			this.positions  				= this.json.data;
		}
		else {
			if (this.titleParam !== '') {
				this.indexSearch();
			} else if (this.category !== '') {
				this.indexCategory();
			} else {
				this.getIndex();
			}	
		}

		this.searchOptions();
		this.isLoggedIn();

		this.scrollWindow();
	}

	initForm() {
		this.form = this.fb.group({
            title: 					['', ],
            type: 					['title', ],
            category: 				['', ],
            location: 				['', ],
            specialization_id: 		['', ],
            minimum_salary: 		['', [Validators.pattern('^[0-9]*$')]],
            maximum_salary: 		['', [Validators.pattern('^[0-9]*$')]],
            hourly_wages: 			['', [Validators.pattern('^[0-9]*$')]],
            experience_years: 		['', [Validators.pattern('^[0-9]*$')]],
            job_types: 				[this.job_types, ],
            posted_since: 			['all', ],
        });
	}

	ngOnDestroy() {

		if (this.router.routerState.snapshot.url.indexOf('positions/') < 0) {
	        this.data = null;
	    }
	}

	ngAfterViewInit() {

		if(this.data !== null) {
			this.json 						= this.data;
			this.positions  				= this.json.data;
			this.pageMaker = this.pager.getPager(this.json.total, this.json.current_page);
		}
	}

    // ngOnChanges() {

    // 	console.log('onchanges');
    // }

    // ngAfterViewChecked() {        
    //     // this.scrollWindow();        
    // } 


    saveScrollPosition(event) {
    	this.scroll = window.pageYOffset;
    }

	get f() { return this.form.controls; }

	private checkParams() {
		this.route
			.queryParams
			.subscribe(params => {
				this.titleParam = params['title'] 		|| '';
				this.advanced 	= params['advanced'] 	|| 'false';
				this.category 	= params['category'] 	|| '';
				// this.page 		= params['page'] 		|| 1;
		});

			this.location.replaceState('/positions');
	}

	scrollWindow():void {

		setTimeout(()=>{
			let el = (document.getElementById(this.scroll));

			if(el !== null) {
				el.scrollIntoView();
				window.scrollBy(0, -115);
			}
			else 
				window.scroll(0,0);

	    },1000);
	}

	openPosition(positionId: string): void { 

		this.router.navigate(['/positions/' + positionId]);

		this.scroll = positionId;
  	}

	public getIndex(): void {

		this.apiService.getPositions(this.page).subscribe(data => {

			if (data.status == false) {
				alert(data.message);
			} else {
				this.json 						= data.data;
				this.positions  				= this.json.data;

				this.data = this.json;

				this.pageMaker = this.pager.getPager(this.json.total, this.json.current_page);
			}
		});
	}

	toggleSearch(): void {

		if (this.advanced == 'true') {
			this.advanced = 'false';
		} else {
			this.advanced = 'true';
		}
	}

	jobTypes(event) {
	    if ( event.target.checked ) {
	         this.job_types.push(event.target.id);
	    } else {
	    	const index = this.job_types.findIndex(d => d === event.target.id); //find index in your array
        	this.job_types.splice(index, 1);
	    }
	    this.form.controls['job_types'].setValue(this.job_types);
	}

	clear(): void {

		this.form.reset();
		this.initForm();
	}

	simpleSearch(): void {
		this.clear();

		this.form.controls['title'].setValue(this.titleParam);
		this.form.controls['type'].setValue('title');

		this.search();
	}

	indexSearch(): void {
		this.form.controls['title'].setValue(this.titleParam);
		this.form.controls['type'].setValue('title');

		this.search();
	}

	indexCategory(): void {
		this.form.controls['category'].setValue(this.category);

		this.search();
	}

	search(): void {

		this.submitted = true;

		this.location.replaceState('/positions');

		if (this.form.valid) {
			this.positions 					= [];
			this.positionsLength  			= 0;
			this.advanced = 'false';
			this.page = 1;


			this.apiService.searchJob(this.form.value, this.page).subscribe(data => {

				if (data.status) {
					// this.form.reset();
					this.submitted = false;
					this.json  					= data.data;
					this.positions  			= this.json.data;
					this.positionsLength  		= Object.keys(this.positions).length;

					this.data = this.json;

					this.pageMaker = this.pager.getPager(this.json.total, this.json.current_page);
				} else {
					alert(data.message);
				}
			});
		}
	}

	searchOptions(): void {

		this.apiService.searchOptions().subscribe(data => {

			this.options = data.data;
		});
	}

	isLoggedIn(): void {

		if (localStorage.getItem('user')) {
			this.loggedIn =  true;
		} else {
			this.loggedIn =  false;
		}
	}

	setPage(page: number) {

        this.page = page;

        this.positions = null;
		this.data = null;

		if (this.form.controls['title'].value !== '')
			this.search();
		else if(this.form.controls['category'].value != '')
			this.indexCategory();
		else
			this.getIndex();
    }
}
