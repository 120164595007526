import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-privacy-settings',
	templateUrl: './privacy-settings.component.html',
	styleUrls: ['./privacy-settings.component.css']
})
export class PrivacySettingsComponent implements OnInit {

	data: any;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
		) { }

	ngOnInit() {
		this.index();
	}

	index(): void {
		window.scroll(0, 0);
		this.apiService.personalPrivacy().subscribe(data => {

			if (data.status) {
				this.data = data.data;
			} else {
				alert(data.message);
			}

			console.log(data.message);
			console.log(data.data);
		});
	}

	update(form: NgForm): void {

		this.apiService.personalUpdatePrivacy(form.value).subscribe(data => {

			alert(data.message);
		});

	}
}
