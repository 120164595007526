import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router'
import { Title } from '@angular/platform-browser';

declare var gtag: Function;
declare const fbq: any;

declare function ready(): any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    constructor(
        private router: Router,
        private titleService: Title
        ){ }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                (<any>window).gtag('config', 'UA-148326806-1', {
                    // 'page_title' : this.titleService.getTitle(),
                    'page_path': event.urlAfterRedirects
                });
                // (<any>window).fbq('track', {
                //     // 'page_title' : this.titleService.getTitle(),
                //     'page_path': event.urlAfterRedirects
                // });
                (<any>window).fbq('track', event.urlAfterRedirects);
            }
        });

        ready();
    }

}
