import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ExcelService }                   from '../excel.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

import { Observable, of }                   from 'rxjs';
import { catchError, map, tap }             from 'rxjs/operators';

import { saveAs } from 'file-saver';

@Component({
    selector: 'app-maa-admin',
    templateUrl: './maa-admin.component.html',
    styleUrls: ['./maa-admin.component.css'],
    providers: [ExcelService]
})
export class MaaAdminComponent implements OnInit {

    posts: any;
    data: any;
    admins: any;
    enquiriesList: any;
    crms: any;

    type: String;
    title: String;
    name: String;
    nameHint: String;
    crm: String;
    crmNew = true;

    selectedType: String;
    searchUserType: String;
    searchCorporateType: String;

    personal: any[] = [];
    corporate: any[] = [];

    form: FormGroup;
    crmForm: FormGroup;
    banForm: FormGroup;
    corporateForm: FormGroup;
    reportForm: FormGroup;
    adminForm: FormGroup;


    submitted = false;

    constructor(
        private route: ActivatedRoute,
        private apiService: ApiService,
        private router: Router,
        private location: Location,
        private fb: FormBuilder,
        private excelService: ExcelService
        ) { }

    ngOnInit() {
        this.getUser();


        this.index();

        this.form = this.fb.group({
            name: 		['', 	Validators.required],
            slug: 		['', 	],
        });

        this.crmForm = this.fb.group({
            name:         ['',     Validators.required],
            slug:         ['',     ],
            type:         ['',     ],
        });

        this.banForm = this.fb.group({
            email:      ['',  []],
            name: 		['', 	]
        });

        this.reportForm = this.fb.group({
            personal:   [this.personal, ],
            corporate:  [this.corporate, ],
            filters:    this.fb.group({
                min_age: ['', Validators.pattern('^[0-9]*$')],
                max_age: ['', Validators.pattern('^[0-9]*$')],
                min_current: ['', Validators.pattern('^[0-9]*$')],
                max_current: ['', Validators.pattern('^[0-9]*$')],
                min_expected: ['', Validators.pattern('^[0-9]*$')],
                max_expected: ['', Validators.pattern('^[0-9]*$')],
                gender: ['all', ],
                race_id: ['all', ],
                nationality_id: ['all', ],
                state: ['all', ],
            })
        });

        this.corporateForm = this.fb.group({
            email:        ['',  []],
            name:         ['',     ]
        });

        this.adminForm = this.fb.group({
            email:        ['',  [Validators.required, Validators.email]],
        });



        this.type = null;
        this.title = 'Countries';
        this.name = 'Country';

        this.selectedType = 'verify-corporate';

        this.fillAllReports();

    }

    get a() { return this.adminForm.controls; }
    get cr() { return this.crmForm.controls; }
    get f() { return this.form.controls; }
    get b() { return this.banForm.controls; }
    get c() { return this.corporateForm.controls; }
    get r() { return this.reportForm.controls; }

    getUser(): void {

        if (localStorage.getItem('user') !== null) {
            const id = JSON.parse(localStorage.getItem('user')).id;

            if (!id) {
                this.router.navigate(['/']);
            }

            // if(this.id !== '57')
                // this.router.navigate(['/']);
        } else {
            this.router.navigate(['/']);
        }
    }

    index(): void {

        this.apiService.getCorporateRequests().subscribe(data => {

            if (data.status) {
                this.posts  					= data.data;
            } else {
                alert(data.message);
            }
        });
    }



    manage(type: String): void {

        this.crms = null;
        // if(type == 'countries') {
        //     this.type = type;
        //     this.title = 'Countries';
        //     this.name = 'Country';
        // }
        if (type == 'education-fields') {
            this.type = type;
            this.title = 'Education Fields';
            this.name = 'Education Field';
        } else if (type == 'industries') {
            this.type = type;
            this.title = 'Industries';
            this.name = 'Industry';
        } else if (type == 'languages') {
            this.type = type;
            this.title = 'Languages';
            this.name = 'Language';
        } else if (type == 'position-levels') {
            this.type = type;
            this.title = 'Position Levels';
            this.name = 'Position Level';
        } else if (type == 'qualifications') {
            this.type = type;
            this.title = 'qualifications';
            this.name = 'Qualification';
        } else if (type == 'races') {
            this.type = type;
            this.title = 'Races';
            this.name = 'Race';
        } else if (type == 'roles') {
            this.type = type;
            this.title = 'Roles';
            this.name = 'Role';
        } else if (type == 'skills') {
            this.type = type;
            this.title = 'Skills';
            this.name = 'Skill';
        } else if (type == 'specializations') {
            this.type = type;
            this.title = 'Specializations';
            this.name = 'Specialization';
        } else if (type == 'universities') {
            this.type = type;
            this.title = 'Universities';
            this.name = 'University';
        }
        // else if(type == 'admin-users') {
        //     this.type = type;
        //     this.title = 'Admin Users';
        //     this.name = 'Admin Users';

        // }

        this.setNew();

        this.getCRM(this.type);
    }

    getCRM(type): void {

        this.apiService.adminGetCRM(type).subscribe(data => {

            if (data.status) {
                this.crms            = data.data;
            } else {
                alert(data.message);
            }
        });
    }

    createCRM(): void {

        this.submitted = true;

        this.crmForm.patchValue({
          type: this.type,
          slug: ''
        });

        if (this.crmForm.valid) {
            this.apiService.adminAddCRM(this.crmForm.value).subscribe(data => {

                if (data.status) {
                    this.getCRM(this.type);
                }

                this.submitted = false;
                this.crmForm.reset();
                alert(data.message);
            });
        }

    }

    edit(crm: any): void {

        this.crmNew = false;

        window.scroll(0, 0);
        // this.new = false;
        this.crm = crm;

        this.crmForm.setValue({
          name: crm.name,
          type: this.type,
          slug: ''
        });
    }

    setNew(): void {

        this.submitted = false;
        this.crmNew = true;
        this.crmForm.reset();
    }

    updateCRM(id): void {

        this.submitted = true;

        this.crmForm.patchValue({
          type: this.type,
          slug: ''
        });

        if (this.crmForm.valid) {
            this.apiService.adminUpdateCRM(id, this.crmForm.value).subscribe(data => {

                if (data.status) {
                    this.getCRM(this.type);
                }

                this.submitted = false;
                this.setNew();
                alert(data.message);
            });
        }
    }

    deleteCRM(id): void {

        this.apiService.adminDeleteCRM(id, this.type).subscribe(data => {

            if (data.status) {
                this.getCRM(this.type);
            }

            this.submitted = false;
            this.crmForm.reset();
            alert(data.message);
        });
    }

    adminUsers(): void {
        this.apiService.adminUsers().subscribe(data => {

            if (data.status) {
                this.admins            = data.data;
            } else {
                alert(data.message);
            }
        });

    }

    addAdminUser(): void {
        this.submitted = true;

        if (this.adminForm.valid) {

            this.apiService.adminAddUser(this.adminForm.value).subscribe(data => {
                    alert(data.message);
                    this.submitted = false;
                    this.adminForm.reset();
                    this.adminUsers();
            });
        }
    }

    removeAdminUser(id): void {

        if (id === 1) {
            alert('Alteraa Technologies is the developer of the system and cannot be removed as Administrator.');
        } else {
            this.apiService.adminRemoveUser(id).subscribe(data => {
                alert(data.message);
                this.adminUsers();
            });
        }
    }

    search(): void {
        this.data = null;

        if (this.banForm.valid) {

            if (this.banForm.controls['name'].value === '' && this.banForm.controls['email'].value === '') {

                alert('One of the search fields must be filled in.');
            } else {

                if (this.banForm.controls['name'].value === '') {
                    this.searchUserType = 'single';
                } else if (this.banForm.controls['email'].value === '') {
                    this.searchUserType = 'multiple';
                     } else {
                    this.searchUserType = 'single';
                     }

                this.apiService.searchSystemUser(this.banForm.value).subscribe(data => {
                    if (data.status) {
                        this.data = data.data;
                    } else {
                        alert(data.message);
                        this.banForm.reset();
                    }
                });
            }
        }
    }

    // /TODO
    searchCorporate(): void {

        this.data = null;

        if (this.corporateForm.controls['name'].value === '' && this.corporateForm.controls['email'].value === '') {

            alert('One of the search fields must be filled in.');
        } else {

            if (this.corporateForm.controls['name'].value === '') {
                this.searchCorporateType = 'single';
            } else if (this.corporateForm.controls['email'].value === '') {
                this.searchCorporateType = 'multiple';
            } 
            // else {
            //     this.searchCorporateType = 'single';
            // }

            this.apiService.searchCorporate(this.corporateForm.value).subscribe(data => {
                if (data.status) {
                    this.data = data.data;
                } else {
                    alert(data.message);
                    this.corporateForm.reset();
                }
            });

        }
    }

    ban(id: number): void {
        this.apiService.banSystemUser(id).subscribe(data => {
            alert(data.message);
            this.submitted = false;
            this.data = null;
            this.banForm.reset();
// }
        });
    }

    banCorporate(data): void {

        var type = '';

        if(data.corporate.user_id) 
            type = 'company';
        else
            type = 'subsidiary';


        this.apiService.banCorporate(type, data.corporate.id).subscribe(data => {
            alert(data.message);
            this.submitted = false;
            this.data = null;
            this.corporateForm.reset();
// }
        });
    }

    unban(id: number): void {
        this.apiService.unbanSystemUser(id).subscribe(data => {
            alert(data.message);
            this.submitted = false;
            this.data = null;
            this.banForm.reset();
// }
        });
    }

    unbanCorporate(data): void {

        var type = '';

        if(data.corporate.user_id) 
            type = 'company';
        else
            type = 'subsidiary';

        this.apiService.unbanCorporate(type, data.corporate.id).subscribe(data => {
            alert(data.message);
            this.submitted = false;
            this.data = null;
            this.corporateForm.reset();
// }
        });
    }

    form44(data): void {

        this.apiService.adminCorporateGetSSM(data.corporate.id).subscribe(data => {

                const blob = new Blob([data], {type: 'application/pdf'});
                if (blob.size > 2) {
                    const file = window.URL.createObjectURL(blob);
                    saveAs(file, 'form44.pdf');
                } else {
                    alert('No Form 44 document uploaded previously');
                }
            });
    }

    form9(data): void {

        this.apiService.adminCorporateGetForm9(data.corporate.id).subscribe(data => {

                const blob = new Blob([data], {type: 'application/pdf'});
                if (blob.size > 2) {
                    const file = window.URL.createObjectURL(blob);
                    saveAs(file, 'form9.pdf');
                } else {
                    alert('No Form 9 document uploaded previously');
                }
            });
    }

    profile(id: number): void {

        window.open( '/maa-admin/user-profile/' + id);
    }

    corporateProfile(data: any): void {

        if(data.corporate.user_id)
            window.open( '/maa-admin/corporate-profile/' + data.id);
        else
            window.open( '/maa-admin/corporate-profile/subsidiary/' + data.corporate.id);
    }




    issues(): void {

        this.apiService.adminSystemIssues().subscribe(data => {

            if (data.status) {
                this.posts            = data.data;
            } else {
                alert(data.message);
            }
        });
    }

    resolve(issue: any): void {

        this.apiService.adminSystemIssueResolve(issue.id).subscribe(data => {
            if (data.status) {
                this.issues();
            } else {
                alert(data.message);
            }
        });
    }

    enquiries(): void {

        this.apiService.adminSystemEnquiries().subscribe(data => {

            if (data.status) {
                this.enquiriesList            = data.data;
            } else {
                alert(data.message);
            }
        });
    }

    resolveEnquiry(id): void {

        this.apiService.adminSystemEnquiryResolve(id).subscribe(data => {
            if (data.status) {
                this.enquiries();
            } else {
                alert(data.message);
            }
        });
    }




    selected(type: String): void {

        this.selectedType = type;
        this.type = null;
        this.submitted = false;
        this.data = null;

        if (type === 'verify-corporate') {
            this.index();
        } else if (type === 'reported-issues') {
            this.issues();
             } else if (type === 'users-report') {
            this.reportOptions();
             } else if (type === 'admin-users') {
            this.adminUsers();
             } else if (type === 'enquiries') {
            this.enquiries();
             }
    }

    reportOptions(): void {
        this.apiService.getReportOptions().subscribe(data => {

            if (data.status) {
                this.reportOptions                      = data.data;
            } else {
                alert(data.message);
            }
        });
    }

    verify(post: any, status: String) {

        const params = {};

        if (status === 'approved') {
            params['status'] = status;
            params['verified'] = 1;
        } else if (status === 'rejected') {
            params['status'] = status;
            params['verified'] = 0;
        }

        this.apiService.verifyCorporateRequest(params, post.id).subscribe(data => {
            if (data.status) {
                this.index();
            } else {
            }
            alert(data.message);
        });
    }

    getSSM(post: any): void {

        this.apiService.adminCorporateGetSSM(post.id).subscribe(data => {

                const blob = new Blob([data], {type: 'application/pdf'});
                if (blob.size > 2) {
                    const file = window.URL.createObjectURL(blob);
                    saveAs(file, post.company_name + '_FORM44.pdf');
                } else {
                    alert('No Form 44 document uploaded previously');
                }
            });
    }


    getForm9(post: any): void {

        this.apiService.adminCorporateGetForm9(post.id).subscribe(data => {

                const blob = new Blob([data], {type: 'application/pdf'});
                if (blob.size > 2) {
                    const file = window.URL.createObjectURL(blob);
                    saveAs(file, post.company_name + '_FORM9.pdf');
                } else {
                    alert('No Form 9 document uploaded previously');
                }
            });
    }


    fillAllReports(): void {

        // PERSONAL
        this.personal.push('first_name');
        this.personal.push('last_name');
        this.personal.push('age');
        this.personal.push('gender');
        this.personal.push('race');
        this.personal.push('nationality');
        this.personal.push('address');
        this.personal.push('city');
        this.personal.push('state');
        this.personal.push('zipcode');
        this.personal.push('phone');
        this.personal.push('email');
        this.personal.push('current_salary');
        this.personal.push('expected_salary');
        this.personal.push('jobs_applied');
        this.personal.push('casual_posted');
        this.personal.push('corporate_posted');

        this.reportForm.controls['personal'].setValue(this.personal);


        // CORPORATE
        this.corporate.push('co_name');
        this.corporate.push('co_reg_no');
        this.corporate.push('co_est_date');
        this.corporate.push('co_address');
        this.corporate.push('co_city');
        this.corporate.push('co_state');
        this.corporate.push('co_phone');
        this.corporate.push('co_email');
        this.corporate.push('co_website');
        this.corporate.push('co_industry');

        this.reportForm.controls['corporate'].setValue(this.corporate);
    }

    personalAtts(event) {
        if ( event.target.checked ) {
            this.personal.push(event.target.id);
        } else {
            const index = this.personal.findIndex(d => d === event.target.id);
            this.personal.splice(index, 1);
        }
        this.reportForm.controls['personal'].setValue(this.personal);
    }

    corporateAtts(event) {
        if ( event.target.checked ) {
            this.corporate.push(event.target.id);
        } else {
            const index = this.corporate.findIndex(d => d === event.target.id);
            this.corporate.splice(index, 1);
        }
        this.reportForm.controls['corporate'].setValue(this.corporate);
    }

    generate() {

        this.submitted = true;

        if (this.reportForm.valid) {
            this.apiService.adminUsersReport(this.reportForm.value).subscribe(data => {
                if (data.status) {
                    for (let i = Object.keys(data.data.personal).length - 1; i >= 0; i--) {
                        if (data.data.personal[i].hasOwnProperty('race_id') && data.data.personal[i].race_id != null) {
                            data.data.personal[i].race = data.data.personal[i].race.name;
                        }

                        if (data.data.personal[i].hasOwnProperty('nationality_id') && data.data.personal[i].nationality_id != null) {
                            data.data.personal[i].nationality = data.data.personal[i].nationality.name;
                        }
                        if (data.data.personal[i].hasOwnProperty('banned') && data.data.personal[i].banned.banned != null) {
                            data.data.personal[i].banned = data.data.personal[i].banned.banned;
                        }
                    }

                    for (let i = Object.keys(data.data.corporate).length - 1; i >= 0; i--) {
                        if (data.data.corporate[i].hasOwnProperty('industry_id') && data.data.corporate[i].industry_id != null) {
                            data.data.corporate[i].industry = data.data.corporate[i].industry.name;
                        }
                    }

                    for (let i = Object.keys(data.data.subsidiary).length - 1; i >= 0; i--) {
                        if (data.data.subsidiary[i].hasOwnProperty('industry_id') && data.data.subsidiary[i].industry_id != null) {
                            data.data.subsidiary[i].industry = data.data.subsidiary[i].industry.name;
                        }
                        if (data.data.subsidiary[i].hasOwnProperty('corporate_profile') && data.data.subsidiary[i].corporate_profile != null) {
                            data.data.subsidiary[i].corporate_profile = data.data.subsidiary[i].corporate_profile.company_name;
                        }
                    }

                    this.excelService.exportAsExcelFile(data.data.personal, data.data.corporate, data.data.subsidiary, 'maaxjobs_users_report');
                } else {
                    alert(data.message);
                }
            });

            this.submitted = false;
        }

    }
}
