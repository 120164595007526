import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } 			from  '../api.service';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.css']
})
export class UserActivationComponent implements OnInit {

	code: any;
	message: any;

  constructor(
  		private  apiService:  ApiService,
		private route: ActivatedRoute,
    	private router: Router
    ) { }

  ngOnInit() {

  	this.route
		.queryParams
		.subscribe(params => {
			this.code = params['code'] || '';
			this.message = 'Loading...';
			console.log(this.code);
			// this.location.replaceState('/positions');

			this.activateAccount();
	});

  }

  activateAccount() {
		this.apiService.activate(this.code).subscribe(data => {

		this.message 	= data.message;
	});
  }

}
