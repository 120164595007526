import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
import { saveAs } from 'file-saver';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-search-candidates',
  templateUrl: './search-candidates.component.html',
  styleUrls: ['./search-candidates.component.css']
})
export class SearchCandidatesComponent implements OnInit {

  	post: any;
  	selectedType: String;

	userId: null;

	advanced = true;
	submitted = false;
	saved = false;

	otherTag = false;

	filterData: any;
	tags: any;


	hideme: any[] = [];
	races: any[] = [];
	nationalities: any[] = [];
	specializations: any[] = [];
	position_roles: any[] = [];
	industries: any[] = [];
	qualifications: any[] = [];
	study_fields: any[] = [];
	languages: any[] = [];
	skills: any[] = [];

	form: FormGroup;
	saveForm: FormGroup;
	tagForm: FormGroup;


	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder

		) {

		this.router.events.subscribe((e: any) => {
	      this.advanced = true;
	    });
	}

	ngOnInit() {

		this.index();
		this.getTags();

		// this.selectedType = 'pending';

		this.form = this.fb.group({
			gender: ['', ],
			min_age: ['', Validators.pattern('^[0-9]*$')],
			max_age: ['', Validators.pattern('^[0-9]*$')],
			min_salary: ['', Validators.pattern('^[0-9]*$')],
			max_salary: ['', Validators.pattern('^[0-9]*$')],
			min_years: ['', Validators.pattern('^[0-9]*$')],
			max_years: ['', Validators.pattern('^[0-9]*$')],
			last_updated: ['', ],
            races: [this.races, ],
            nationalities: [this.nationalities, ],
            specializations: [this.specializations, ],
            position_roles: [this.position_roles, ],
            industries: [this.industries, ],
            qualifications: [this.qualifications, ],
            study_fields: [this.study_fields, ],
            languages: [this.languages, ],
            skills: [this.skills, ],
            status: ['', ]
        });

        this.saveForm = this.fb.group({
			tag_id: ['', Validators.required],
			candidate_user_id: ['', Validators.required],
        });

        this.tagForm = this.fb.group({
			name: ['', Validators.required],
        });
	}

	get f() { return this.form.controls; }
	get s() { return this.saveForm.controls; }
	get t() { return this.tagForm.controls; }

	index(): void {
		window.scroll(0, 0);
		this.apiService.corporateSearchCandidatesOptions().subscribe(data => {

			this.filterData = data['data'];
		});
	}

	searchCandidates() {
		this.submitted = true;
		this.tag('');

		if (this.form.valid) {
			this.advanced = false;

			this.apiService.corporateSearchCandidates(this.form.value).subscribe(data => {

				if (data.status) {
					this.submitted 	= false;
					this.post 		= data.data;
				} else {
					alert(data.message);
				}
			});

			window.scroll(0, 0);
		}
	}

	clearFilter(): void {

		this.form.reset();
		this.races = [];
		this.nationalities = [];
		this.specializations = [];
		this.position_roles = [];
		this.industries = [];
		this.qualifications = [];
		this.study_fields = [];
		this.languages = [];
		this.skills = [];
	}

	onChangeAdd(item, type) {

		this[type].push(item);
    }

	onChangeRemove(item, type) {

		const index = this[type].findIndex(d => d.id === item.id); //find index in your array
        this[type].splice(index, 1); //remove element from array
    }

    tag(value): void {
		if (value == 'other') { // 7 == Others
			this.saveForm.get('tag_id').clearValidators();
			this.saveForm.controls['tag_id'].setValue('');
			this.tagForm.get('name').setValidators([Validators.required]);
			this.otherTag = true;
		} else {
			this.saveForm.controls['tag_id'].setValue(value);
		}

		this.hideme = [];
	}

	closeOthers(field): void {
		if (field == 'tag') {
			this.tagForm.get('name').clearValidators();
			this.tagForm.controls['name'].setValue('');
			this.saveForm.get('tag_id').setValidators([Validators.required]);
			this.otherTag = false;
			this.submitted = false;
		}
	}

	getTags(): void {

		this.apiService.corporateGetTags().subscribe(data => {

			this.tags = data['data'];
		});
	}

	addTag(): void {
		this.submitted = true;

		if (this.tagForm.valid) {

			this.apiService.corporateAddTag(this.tagForm.value).subscribe(data => {
				this.submitted = false;
				this.otherTag = false;

				if (data.status) {
					this.tagForm.reset();
					this.getTags();
				}
				alert(data.message);
			});
		}
	}

	// TO BE WORKED ON
	saveCandidate(id, i): void {
		this.saved = true;
		this.saveForm.controls['candidate_user_id'].setValue(id);

		if (this.saveForm.valid) {

			this.apiService.corporateSaveCandidate(this.saveForm.value).subscribe(data => {
				this.saved = false;
				this.hideme[i] = !this.hideme[i];

				if (!data.status) {
					alert(data.message);
				}
			});
		} else {
			window.scroll(0, 0);
		}
	}

	toggleSearch(): void {

		if (this.advanced) {
			this.advanced = false;
			window.scroll(0,0);
		} else {
			this.advanced = true;
		}
	}

	getResume(id: any): void {

		this.apiService.corporateGetCandidateResume(id).subscribe(data => {

				const blob = new Blob([data], {type: 'application/pdf'});
				if (blob.size > 2) {
					const file = window.URL.createObjectURL(blob);
					saveAs(file, 'resume.pdf');
				} else {
					alert('No Resume document uploaded previously');
				}
			});
	}

	profile(id: number): void {

        window.open( '/corporate/search-candidates/' + id);
    }

}
