import { Component, OnInit } 			from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

@Component({
  selector: 'app-my-applications',
  templateUrl: './my-applications.component.html',
  styleUrls: ['./my-applications.component.css']
})
export class MyApplicationsComponent implements OnInit {

  	json;
	applications;
	applicationsLength;

  	token: string;

  constructor(
  		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location
  ) { }



  ngOnInit() {

  	this.index();
  }

  public index(): void {
		window.scroll(0, 0);

		this.apiService.personalApplications().subscribe(data => {

			if (data.status == false) {
				alert(data.message);
			} else {
				this.json 						= data;
				this.applications  				= data.data;
				this.applicationsLength  		= Object.keys(this.applications).length;
			}
		});
	}

	public delete(id: any): void {

		this.apiService.personalApplicationDelete(id).subscribe(data => {

			if (data.status == true) {
				alert(data.message);
				// HIDE APPLY BUTTON
				this.refresh();
			} else {
				alert(data.message);
				this.router.navigate(['/applications']);
			}
		});
	}


	public refresh(): void {
		window.scroll(0, 0);
		this.index();
	}

}
