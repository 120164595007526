import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReuseService {

	json: any = null;
	scroll: any = 0;

  constructor() { }
}
