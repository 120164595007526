import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardGuard implements CanActivate {

	user: object;
  constructor(
  	// private auth: AuthService,
    private router: Router){
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if (localStorage.getItem('user')) {

    	this.user = JSON.parse(localStorage.getItem('user'));

    	for (var i = 0; i < this.user['roles'].length; i++) {
		    if (this.user['roles'][i].slug == "administrator")
		        return true;
		}
  	}

    this.router.navigate(["/personal/profile"]);
    
    return false;
  }
}
