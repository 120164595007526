import { Component, OnInit, Input } 		from '@angular/core';
import { ActivatedRoute } 					from '@angular/router';
import { Location } 						from '@angular/common';
import { ApiService } 						from  '../api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { Hero } 							from '../hero';
// import { HeroService }  					from '../hero.service';

@Component({
	selector: 'app-position-detail',
	templateUrl: './position-detail.component.html',
	styleUrls: ['./position-detail.component.css']
})
export class PositionDetailComponent implements OnInit {

	json;
	position;
	applied = false;
	bookmarked = false;

	form: FormGroup;

	submitted = false;

	constructor(
		private route: ActivatedRoute,
	  	private location: Location,
	  	private apiService:  ApiService,
	  	private fb: FormBuilder
  	) { }

	ngOnInit() {
		this.refresh();

		 this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
	}

	get f() { return this.form.controls; }

	public getPosition(): void {
	  const id = +this.route.snapshot.paramMap.get('id');

	  this.apiService.getPosition(id).subscribe(data => {

			if (data.status == false) {
				alert(data.message);
				this.goBack();
			} else {
				this.json 						= data;
				this.position  					= data.data;
			}
		});
	}

	public apply(id: number): void {

		const param = {
			hiring_id: id
		};

		this.apiService.apply(param).subscribe(data => {

			if (data.status == true) {
				alert(data.message);
				// this.applied = true;
				// this.refresh();
				this.goBack();
			} else {
				alert(data.message);
			}
		});
	}

	public bookmark(id: number): void {

		const param = {
			hiring_id: id
		};

		this.apiService.bookmark(param).subscribe(data => {

			if (data.status == true) {
				alert(data.message);
				this.bookmarked = true;
				this.refresh();
				// HIDE APPLY BUTTON
			} else {
				alert(data.message);
			}
		});

	}

	public shareToEmail(id): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.sharePosition(this.form.value, id).subscribe(data => {

				this.form.reset();
				alert(data.message);
				this.submitted = false;
			});
		}
	}

	public goBack(): void {
	  // this.location.back();
	  window.history.back();
	}

	public refresh(): void {
		// window.scroll(0, 0);
		this.getPosition();
	}



}
