import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-subsidiaries-edit',
  templateUrl: './company-subsidiaries-edit.component.html',
  styleUrls: ['./company-subsidiaries-edit.component.css']
})
export class CompanySubsidiariesEditComponent implements OnInit {

	form: FormGroup;
	submitted = false;

	options: any;
	cities: any;
	
	subsidiary: any;

	image:any;

	otherIndustry = false;


  constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder) { }

  
  ngOnInit() {
  		this.index();

		this.form = this.fb.group({
            avatar: ['', [Validators.required]],
            company_name: ['', [Validators.required]],
            about: ['', [Validators.required]],
            since: ['', [Validators.required]],
            ssm: ['', [Validators.required]],
            industry_id: ['', [Validators.required]],
            employees: ['', [Validators.required]],
            address_1: ['', [Validators.required]],
            address_2: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            zipcode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
            country_id: ['', [Validators.required]],
            contact_number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
            contact_email: ['', [Validators.required, Validators.email]],
            latitude: ['', []],
            longitude: ['', []],
			facebook_link: ['', []],
			twitter_link: ['', []],
			google_link: ['', []],
			linkedin_link: ['', []],
			website: ['', []],
			industry: ['', []]
        });
  }

  get f() { return this.form.controls; }


	index(): void {

		this.apiService.corporateAboutCompanyOptions().subscribe(data => {

			this.options 		= data.data;
			this.aboutSubsidiary();
		});
	}

	aboutSubsidiary(): void {

		const id = +this.route.snapshot.paramMap.get('id');

		this.apiService.corporateCompanySubsidiary(id).subscribe(data => {

			if(!data.status) {
				alert(data.message);
				this.router.navigate(['/corporate/subsidiaries']);
			}
			else {
				this.subsidiary 		= data.data;

				this.form.setValue({

		            avatar: this.subsidiary.avatar,
		            company_name: this.subsidiary.company_name,
		            about: this.subsidiary.about,
		            since: this.subsidiary.since,
		            ssm: this.subsidiary.ssm,
		            industry_id: this.subsidiary.industry_id,
		            employees: this.subsidiary.employees,
		            address_1: this.subsidiary.address_1,
		            address_2: this.subsidiary.address_2,
		            city: this.subsidiary.city,
		            state: this.subsidiary.state,
		            zipcode: this.subsidiary.zipcode,
		            country_id: this.subsidiary.country_id,
		            contact_number: this.subsidiary.contact_number,
		            contact_email: this.subsidiary.contact_email,
		            latitude: this.subsidiary.latitude,
		            longitude: this.subsidiary.longitude,
					facebook_link: this.subsidiary.facebook_link,
					twitter_link: this.subsidiary.twitter_link,
					google_link: this.subsidiary.google_link,
					linkedin_link: this.subsidiary.linkedin_link,
					website: this.subsidiary.website,
					industry: ''
				});
			}

			this.getCitiesOfState(this.subsidiary.state);
		});
	}

	getCitiesOfState(stateId): void {

		this.cities = (this.options.states.filter(state => state.name == stateId))[0].cities;
	}

	onChange(value): void {
		if (value == '') {
			this.cities = [];
			this.form.controls['city'].setValue('');
		} else {
			this.cities = [];
			this.form.controls['city'].setValue('');
	    	this.getCitiesOfState(value);
		}
	}

	sameAddress(event) {
		if ( event.target.checked ) {
			this.form.controls['address_1'].setValue(this.subsidiary.address_1);
			this.form.controls['address_2'].setValue(this.subsidiary.address_2);
			this.form.controls['city'].setValue(this.subsidiary.city);
			this.form.controls['state'].setValue(this.subsidiary.state);
			this.form.controls['zipcode'].setValue(this.subsidiary.zipcode);
			this.form.controls['country_id'].setValue(this.subsidiary.country_id);
			this.form.controls['latitude'].setValue(this.subsidiary.latitude);
			this.form.controls['longitude'].setValue(this.subsidiary.longitude);
			this.getCitiesOfState(this.subsidiary.state);
		}
		else {
			this.form.controls['address_1'].setValue('');
			this.form.controls['address_2'].setValue('');
			this.form.controls['city'].setValue('');
			this.form.controls['state'].setValue('');
			this.form.controls['zipcode'].setValue('');
			this.form.controls['country_id'].setValue('');
			this.form.controls['latitude'].setValue('');
			this.form.controls['longitude'].setValue('');
			this.getCitiesOfState('');
		}
	}

	sameContact(event) {
		if ( event.target.checked ) {
			this.form.controls['contact_number'].setValue(this.subsidiary.contact_number);
	    	this.form.controls['contact_email'].setValue(this.subsidiary.contact_email);
		}
		else {
			this.form.controls['contact_number'].setValue('');
	    	this.form.controls['contact_email'].setValue('');
		}
	}

	sameMedia(event) {
	    if ( event.target.checked ) {
	    	this.form.controls['facebook_link'].setValue(this.subsidiary.facebook_link);
	    	this.form.controls['twitter_link'].setValue(this.subsidiary.twitter_link);
	    	this.form.controls['google_link'].setValue(this.subsidiary.google_link);
	    	this.form.controls['linkedin_link'].setValue(this.subsidiary.linkedin_link);
	    	this.form.controls['website'].setValue(this.subsidiary.website);
	    } 
	    else {
	    	this.form.controls['facebook_link'].setValue('');
	    	this.form.controls['twitter_link'].setValue('');
	    	this.form.controls['google_link'].setValue('');
	    	this.form.controls['linkedin_link'].setValue('');
	    	this.form.controls['website'].setValue('');
	    	
	    }
	}

	uploadFile(event): void {
	    const elem = event.target;
	    if (elem.files.length > 0) {
	      this.image = elem.files[0];

	      const formData: FormData = new FormData();
    	  formData.append('file', elem.files[0], elem.files[0].name);

	      this.apiService.corporateSubsidiaryImage(formData).subscribe(data => {

				if (!data['status']) {
					alert(data['message']);
				}
				else {
					this.form.controls['avatar'].setValue(data['data']);
				}
			});
	    }
	}

	updateAboutSubsidiary(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.corporateUpdateAboutSubsidiary(this.form.value, this.subsidiary.id).subscribe(data => {

				this.submitted = false;
				window.scroll(0, 0);

				alert(data.message);

				if(data.status)
					this.router.navigate(['/corporate/subsidiaries']);
				// this.getUser();
			});
		}
	}

	other(value, field): void {
		if (field == 'industry' && value == 58) { // 7 == Others
			this.form.get('industry_id').clearValidators();
			this.form.controls['industry_id'].setValue('');
			this.form.get('industry').setValidators([Validators.required]);
			this.otherIndustry = true;
		}
	}

	closeOthers(field): void {
		if (field == 'industry') {
			this.form.get('industry').clearValidators();
			this.form.controls['industry'].setValue('');
			this.form.get('industry_id').setValidators([Validators.required]);
			this.otherIndustry = false;
		}
	}

}
