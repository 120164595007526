import { Component, OnInit, Input } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {Globals} 			from '../globals';

@Component({
	selector: 'app-index',
	templateUrl: './index.component.html',
	styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

	json;
	positions;
	categories;
	positions_count;
	count;

	form: FormGroup;
    submitted = false;

    loggedIn = false;

    searchKeyword: any;
    advanced: any;


	slideConfig = {
		'slidesToShow': 4,
		'slidesToScroll': 1,
		'responsive': [
		  	{
			    'breakpoint': 980,
			    'settings': {
				    'slidesToShow': 3
				}
			},
		  	{
			    'breakpoint': 767,
			    'settings': {
				    'slidesToShow': 2
				}
			},
			{
			    'breakpoint': 480,
			    'settings': {
				    'slidesToShow': 1
				}
			},
			
		    
		]
	};

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder,
		private globals: Globals
	) { 
	}

	ngOnInit() {
		this.getIndex();

		this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            name: ['', [Validators.required]],
            message: ['', [Validators.required]],
        });
	}

	get f() { return this.form.controls; }

	public getIndex(): void {
		this.apiService.getIndex().subscribe(data => {

				if (data.status == false) {
					alert(data.message);
				} else {
					this.json               = data;
					this.positions          = data.data.positions;
					this.categories         = data.data.categories;
					this.positions_count    = data.data.positions_count;
					this.count    			= data.data.categories;
					this.globals.newMessageCount = data.data.inbox;
				}

				this.isLoggedIn();
		});
	}

	help(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.enquire(this.form.value).subscribe(data => {
			this.submitted = false;
				alert(data.message);
				this.form.reset();
				window.scroll(0, 0);
			});
		}
	}

	advancedRoute(): void {
		this.advanced = 'true';

		this.router.navigate(['/positions'], { queryParams: { advanced: this.advanced } } ) ;
	}

	search(): void {
		this.advanced = 'false';
		// this.router.navigate(['/positions'], { queryParams: { title: event.target.value } } ) ;
		this.router.navigate(['/positions'], { queryParams: { title: this.searchKeyword, advanced: this.advanced } } ) ;
	}

	isLoggedIn(): void {

		if (localStorage.getItem('user')) {
			this.loggedIn =  true;
		} else {
			this.loggedIn =  false;
		}
	}

	jobsByCategory(category: String): void {

		this.router.navigate(['/positions'], { queryParams: { category: category } } );
	}
}
