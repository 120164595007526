import { Component, OnInit, NgModule } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { ngfModule, ngf } from 'angular-file';
// import { HttpClient, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-skill',
	templateUrl: './skill.component.html',
	styleUrls: ['./skill.component.css']
})
export class SkillComponent implements OnInit {

	user: any;
	company: any;
	token: string;
	skill: any;
	skills: any;
	options: any;
	new = true;

	form: FormGroup;
    submitted = false;

    otherSkill = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.index();

		this.form = this.fb.group({
            skill_id: 		['', 	Validators.required],
            proficiency: 	['', 	Validators.required],
            skill: ['', []]
        });
	}

	get f() { return this.form.controls; }

	index(): void {

		this.apiService.personalSkillOptions().subscribe(data => {

			this.options = data.data;
			this.getSkills();
		});
	}

	getSkills(): void {

		this.apiService.personalSkills().subscribe(data => {
			this.skills = data.data;
		});
	}

	create(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalCreateSkill(this.form.value).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.submitted = false;
				} else {
					alert(data.message);
				}

				this.setNew();
				this.index();
			});
		}
	}

	edit(skill: any): void {

		window.scroll(0, 0);
		this.new = false;
		this.skill = skill;

		this.form.setValue({
		  skill_id: skill.skill_id,
		  proficiency: skill.proficiency,
		  skill: ''
		});
	}

	update(form: NgForm): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.personalUpdateSkill(this.form.value, this.skill.id).subscribe(data => {

				if (data.status) {
					this.form.reset();
					this.submitted = false;
				}

				this.setNew();
				this.index();
			});
		}
	}

	delete(skill: any): void {

		this.apiService.personalDeleteSkill(skill.id).subscribe(data => {

			this.setNew();
			this.getSkills();
		});
	}

	setNew(): void {

		this.new = true;
		this.form.reset();
		this.submitted = false;

		this.closeOthers('skill');
	}

	other(value, field): void {
		if (field == 'skill' && value == 69) { // 7 == Others
			this.form.get('skill_id').clearValidators();
			this.form.controls['skill_id'].setValue('');
			this.form.get('skill').setValidators([Validators.required]);
			this.otherSkill = true;
		}
	}

	closeOthers(field): void {
		if (field == 'skill') {
			this.form.get('skill').clearValidators();
			this.form.controls['skill'].setValue('');
			this.form.get('skill_id').setValidators([Validators.required]);
			this.otherSkill = false;
		}

		this.submitted = false;
	}

}
