import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

// @Injectable()
export class ExcelService {
	constructor() { }

	public exportAsExcelFile(personal: any[], corporate: any[], subsidiary: any[], excelFileName: string): void {

	  const personalWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(personal);
	  const corporateWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(corporate);
	  const subsidiaryWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(subsidiary);



	  const workbook: XLSX.WorkBook = { Sheets: { 'personal': personalWorksheet, 'corporate': corporateWorksheet, 'subsidiary': subsidiaryWorksheet }, SheetNames: ['personal', 'corporate', 'subsidiary'] };


	  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

	  this.saveAsExcelFile(excelBuffer, excelFileName);
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
	   const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
	   FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
	}
}
