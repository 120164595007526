import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobs-posted',
  templateUrl: './jobs-posted.component.html',
  styleUrls: ['./jobs-posted.component.css']
})
export class JobsPostedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
